import { useState } from "react";

import "../common/Card.scss";
import "./Work.scss";

import doc from "assets/svg/ph_file.svg";
import doc_edit from "assets/svg/Kebab button.svg";

import { updateDoc, deleteDocs } from "utils/api";

import { queryClient } from "App";

import WorkForm from "./WorkForm";
import { DeleteConfirmationModal } from "../common/DeleteConfirmation/DeleteConfirmationModal";

import { toast } from "react-toastify";
import DropDown from "../common/DropDown/DropDown";
import { formatDate } from "screens/dashboard/helpers/formatDate";

const WorkCard = (props) => {
  const { data, key, type } = props;
  const { docId, docUrl, docType, meta } = data;
  const [isLoading, setLoading] = useState(false);
  const { company, designation, startDate, endDate } = JSON.parse(meta);
  const [editing, setEditing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const saveDocUpdate = async (form) => {
    try {
      setLoading(true);

      try {
        await updateDoc(docId, form);
        queryClient.invalidateQueries("data_degree");
        toast.success("Successfully updated");
      } catch (err) {
        toast.error("Something went wrong!");
      }

      setEditing(false);
      setModalVisible(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function getFileName(url) {
    var urlObj = new URL(url);

    var pathComponents = urlObj.pathname.split("/");

    var fileName = pathComponents[pathComponents.length - 1];

    var decodedFileName = decodeURIComponent(fileName);

    return decodedFileName;
  }

  const handleDeleteDocClick = async () => {
    setIsOpen(true);
    setModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      await deleteDocs(docId);
      toast.success("Successfully deleted");
      queryClient.invalidateQueries("data_degree");
    } catch (err) {
      toast.error("Something went wrong!");
      console.log(err);
    }

    setIsOpen(false);
  };

  return editing ? (
    <WorkForm
      data={JSON.parse(meta)}
      docId={docId}
      onSave={saveDocUpdate}
      isEdit={editing}
      loading={isLoading}
      type={type}
      onCancel={() => {
        setEditing(false);
        setModalVisible(false);
      }}
      style={{ marginBottom: "1rem" }}
    />
  ) : (
    <div
      className="contain_language work-card"
      key={key}
      onClick={() => {
        setModalVisible(false);
      }}
    >
      <div className="left-contain">
        <div className="complete-left">
          <p
            className="degree-name truncate"
            style={{ fontSize: "16px", fontWeight: "600" }}
          >
            {company}
          </p>
          <p
            className="college-name truncate"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {designation}
          </p>
          <p
            className="college-name"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              marginTop: "0.9rem",
            }}
          >
            {formatDate(startDate)} - {formatDate(endDate)}
          </p>
        </div>
        <img
          className="mobile_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>
      <div className="right-side">
        <div
          className="wrap_section"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(docUrl);
          }}
        >
          <div className="right-contain">
            <img src={doc} alt="" />
            <div className="file_name">
              <p className="filename">{getFileName(docUrl)}</p>
              {/* <p className="filesize">{(file.size / (1024 * 1024)).toFixed(2)}MB</p> */}
            </div>
          </div>
        </div>
        <img
          className="desktop_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>

      <DeleteConfirmationModal
        title="Work"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleDelete={handleDelete}
      />

      {modalVisible && (
        <DropDown
          setEditing={setEditing}
          handleDeleteDocClick={handleDeleteDocClick}
        />
      )}
    </div>
  );
};

export default WorkCard;
