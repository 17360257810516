import { useEffect } from "react";
import AppContextProvider from "./context/app_context";
import LetterToAbroad from "./LetterToAbroad";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "./constants/components/error";
export const queryClient = new QueryClient();
function App() {
  const env = "prod";
  useEffect(() => {
    localStorage.setItem("ENV", env);
  }, [env]);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <LetterToAbroad />
        </AppContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
