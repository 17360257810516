import { useContext } from "react";
import { AppContext } from "context/app_context";

const Overview = ({ applications }) => {
  const { user } = useContext(AppContext);
  const uniqueUniversityNames = [...new Set(applications.map(app => app.universityName))];
  const uniqueCourseNames = [...new Set(
    applications.flatMap(app => app.course.split(',').map(course => course.trim()))
  )];
  return (
    <div className="overview_container">
      <div className="title_overview">Overview</div>
      <div className="overview_inner">
        <div className="frame">
          <div className="frame_title">
            {uniqueUniversityNames.length || 0}
          </div>
          <div className="frame_label">Applied Universites</div>
        </div>
        <div className="frame">
          <div className="frame_title">
            {uniqueCourseNames.length || 0}
          </div>

          <div className="frame_label">Applied Courses</div>
        </div>
        <div className="frame">
          {/* <div className="frame_title">{applications?.length || 0}</div> */}
          <div className="frame_title">{applications.filter(app => app.admissionStatus === "Confirmed").length  || 0} +</div>
          <div className="frame_label">Admits</div>
        </div>
        {user.expectedIntake ? 
          <div className="frame desktop-only">
            <div className="frame_title">
              {user.expectedIntake}
              
            </div>
            <div className="frame_label">Expected Intake</div>
          </div>  : ""
      }
        
      </div>
    </div>
  );
};

export default Overview;
