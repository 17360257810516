import { useEffect } from "react";
import { toast} from "react-toastify";
import { AlertType } from "../constants/enums";
import { getUser } from "../utils/local";

const { createContext, useState } = require("react");

export const AppContext = createContext({
  isLoading: false,
  setLoading: () => {},
  showAlert: () => {},
  user: {},
  setUser: () => {},
});

const AppContextProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    loadLocals();
  }, []);

  const loadLocals = () => {
    let _localUser = getUser();
    let user = JSON.parse(_localUser);
    setUser(user);
  };

  const toastStyles = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const showAlert = (message, alertType) => {
    switch (alertType) {
      case AlertType.ERROR:
        toast.error(message, toastStyles);
        break;
      case AlertType.SUCCESS:
        toast.success(message, toastStyles);
        break;
      case AlertType.INFO:
        toast.info(message, toastStyles);
        break;
      case AlertType.WARNING:
        toast.warn(message, toastStyles);
        break;
      default:
        toast({
          message,
          ...toastStyles,
        });
        break;
    }
  };

  const values = {
    showAlert,
    setLoading,
    isLoading,
    user,
    setUser,
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
export default AppContextProvider;
