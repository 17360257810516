import "./Events.scss";

const Events = ({ title, host, date, moth, eventType, time }) => {
  return (
    <div className="event_container">
      <div className="event_title">{title}</div>
      <div className="event_author">{host}</div>
      <div className="event_details">
        <div className="event_details_section_one">
          <div className="event_date">{date}</div>
          <div className="event_month">{moth}</div>
        </div>
        <div className="event_details_section_two">
          <div className="event_type">Event Type : {eventType}</div>
          <div className="event_time">{time}</div>
        </div>
      </div>
    </div>
  );
};

export default Events;
