import { useRef, useState, useEffect } from "react";

import "../../profile.scss";

import { Spinner } from "@chakra-ui/react";

import UploadContainer from "../common/UploadContainer";
import SelectDate from "../common/SelectDate";
import { HorizontalLine } from "../common/HorizontalLine/HorizontalLine";

const WorkForm = (props) => {
  const { key, data, onSave, isEdit, onCancel, type, loading } = props;
  const { company, designation, startDate, endDate } = data;
  const [doc, setDoc] = useState("");
  const [date, setDate] = useState({ from: null, to: null });
  const [meta, setMeta] = useState({});
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState({
    company: "Required",
    designation: "Required",
    doc: "Required",
    date: "Required",
  });
  const inputDoc = useRef(null);
  useEffect(() => {
    setMeta({ company, designation, startDate, endDate });
  }, [company, designation, startDate, endDate, isEdit]);

  const handleWorkFile = (file) => {
    setDoc(file);
  };

  const handleCompany = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, company: "Required" });
    } else {
      setError({ ...error, company: "" });
    }
  };

  const handleDesignation = (e) => {
    handleChangeInput(e);
    if (e.target.value.length === 0) {
      setError({ ...error, designation: "Required" });
    } else {
      setError({ ...error, designation: "" });
    }
  };

  const handleStartDate = (e) => {
    console.log(e);
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, startDate: "Required" });
    } else {
      setError({ ...error, startDate: "" });
    }
  };

  const handleEndDate = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, endDate: "Required" });
    } else {
      setError({ ...error, endDate: "" });
    }
  };

  const handleSave = () => {
    console.log(error);
    if (Object.values(error).every((value) => value === "")) {
      const updateForm = new FormData();
      console.log(meta);
      updateForm.append("meta", JSON.stringify(meta));
      updateForm.append("type", type);
      if (doc) {
        updateForm.append("doc", doc);
      }

      onSave(updateForm);
    } else {
      setUpdate(true);
    }
  };

  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    setMeta({ ...meta, [name]: value });
  };

  return (
    <div className="education-main-container" key={key}>
      <form className="education-container">
        <div className="degree-container">
          <div className="form-item">
            <label htmlFor="">Company Name</label>
            <input
              type="text"
              name="company"
              id="company"
              placeholder="Company name"
              value={meta.company}
              onChange={handleCompany}
              style={
                update && error.company
                  ? { border: "1px solid red", width: "auto" }
                  : { width: "auto" }
              }
            />
            {update && error.company ? (
              <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                *{error.company}
              </h6>
            ) : (
              <></>
            )}
          </div>
          <div className="temp-contain">
            <div className="left">
              <div className="form-item exp-item">
                <label htmlFor="">Designation name</label>
                <input
                  type="text"
                  name="designation"
                  id="college_name"
                  placeholder="Enter Designation"
                  value={meta.designation}
                  onChange={handleDesignation}
                  style={
                    update && error.designation
                      ? { border: "1px solid red", width: "auto" }
                      : { width: "auto" }
                  }
                />
                {update && error.designation ? (
                  <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                    *{error.designation}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
              <div className="thedates">
                <div className="form-item dform">
                  <label htmlFor="">Work Period</label>
                  <SelectDate
                    date={date}
                    setDate={setDate}
                    error={error}
                    setError={setError}
                    update={update}
                    meta={meta}
                    type={"startDate"}
                  />
                  {update && error.date ? (
                    <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                      *{error.date}
                    </h6>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="right">
              <UploadContainer
                doc={doc}
                setDoc={setDoc}
                error={error}
                setError={setError}
                update={update}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="profile-buttons">
        <button
          className="btn-sec"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
        <button
          className="btn-pri"
          onClick={handleSave}
          disabled={loading ? true : false}
        >
          {loading ? <Spinner color="white" /> : `Save`}
        </button>
      </div>

      <HorizontalLine />
    </div>
  );
};
export default WorkForm;
