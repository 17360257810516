import { useRef } from "react";

import docIcon from "assets/svg/ph_file.svg";
import crossIcon from "assets/svg/cross.svg";

import "./uploadcontainer.scss";

const UploadContainer = (props) => {
  const inputDoc = useRef(null);
  const { doc, setDoc, error, setError, update } = props;

  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleWorkFile = (file) => {
    setDoc(file);
    if (!file) {
      setError({ ...error, doc: "Required" });
    } else {
      setError({ ...error, doc: "" });
    }
  };

  const handleFileSize = (sizeInBytes) => {
    var sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return sizeInMB;
  };

  const handleClearDocument = () => {
    inputDoc.current.value = null;
    setDoc("");
  };

  return (
    <div className="doc-container" onClick={handleUploadClick}>
      <label htmlFor="" id="upload">
        Upload Doc
      </label>
      <div
        className="upload-container"
        style={{
          background: doc ? "#f2f4f7" : "",
          border: update && error.doc ? "1px solid red" : "",
        }}
      >
        {doc ? (
          <>
            <div className="left">
              <img src={docIcon} width="32" height="32" alt="document icon" />
            </div>
            <div className="right">
              <h6>{doc.name}</h6>
              <p>{handleFileSize(doc.size) + "MB"}</p>
            </div>
          </>
        ) : (
          "Upload Docs"
        )}

        <input
          type="file"
          ref={inputDoc}
          onChange={({ target }) => {
            handleWorkFile(target.files[0]);
          }}
        />

        {doc && (
          <span
            className="cross"
            onClick={(e) => {
              handleClearDocument();
              e.stopPropagation();
            }}
          >
            <img src={crossIcon} alt="cross icon" />
          </span>
        )}
      </div>
    </div>
  );
};

export default UploadContainer;
