import { useState } from "react";

import "../profile.scss";

import addIcon from "assets/svg/Frame 2.svg";

import { downloadDocuments, uploadFile } from "utils/api";

import { useQuery } from "react-query";

import WorkCard from "./WorkExperienceSection/WorkCard";
import WorkForm from "./WorkExperienceSection/WorkForm";

import { HorizontalLine } from "./common/HorizontalLine/HorizontalLine";

import { toast } from "react-toastify";

import { queryClient } from "App";

const Experience = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormIntern, setShowFormIntern] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const downloadDegreeList = async () => {
    let _degrees = await downloadDocuments();
    return _degrees;
  };

  const {
    data: data_degree,
    error: error_degree,
    isLoading: isLoading_degree,
  } = useQuery("data_degree", downloadDegreeList);

  const work_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Work");

  const intern_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Internship");

  const createNewDoc = async (form) => {
    setLoading(true);
    try {
      let newDoc = await uploadFile(form);
      queryClient.invalidateQueries("data_degree");

      toast.success("Document uploaded successfully");
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setShowForm(false);
      setShowFormIntern(false);
      setLoading(false);
    }
  };

  return (
    <div className="documents-main-container">
      <div className="details-form lang-main-container" id="auto">
        <p className="academics-heading">
          Work{" "}
          <span style={{ fontSize: "10px", color: "grey" }}>
            (Max file size 2MB)
          </span>
        </p>
        {isLoading_degree
          ? `Loading`
          : work_files.map((_degree, i) => {
            return <WorkCard type="Work" data={_degree} key={i} />;
          })}

        <HorizontalLine showForm={showForm} totalCards={work_files.length} />

        {showForm && (
          <WorkForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            type="Work"
            isEdit={false}
            loading={isLoading}
            onCancel={() => {
              setShowForm(false);
            }}
          />
        )}

        {!showForm && (
          <div
            className="add_wrapper"
            onClick={() => {
              setShowForm(true);
            }}
          >
            <img src={addIcon} />
            Add Work
          </div>)}
      </div>

      <div className="lang-main-container">
        <div className="lang-container">
          <p className="academics-heading">
            Internship{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>
          <div className="education-main-container">
            {isLoading_degree
              ? `Loading`
              : intern_files.map((_degree, i) => {
                return <WorkCard type="Work" data={_degree} key={i} />;
              })}

            <HorizontalLine
              showForm={showFormIntern}
              totalCards={intern_files.length}
            />

            {showFormIntern && (
              <WorkForm
                key={0}
                data={{}}
                onSave={createNewDoc}
                type="Internship"
                isEdit={false}
                onCancel={() => {
                  setShowFormIntern(false);
                }}
              />
            )}
            {!showFormIntern && (
              <div
                className="add_wrapper"
                onClick={() => {
                  setShowFormIntern(true);
                }}
              >
                <img src={addIcon} />
                Add Internship
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
