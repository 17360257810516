import "../profile.scss";
import add from "assets/svg/Frame 2.svg";

import { useState, useRef } from "react";
import { downloadDocuments, uploadFile } from "utils/api";

import { useQuery } from "react-query";
import { queryClient } from "App";

import TravelForm from "./travel/TravelForm";
import Travel from "./travel/TravelCard";

import { toast } from "react-toastify";
import { HorizontalLine } from "./common/HorizontalLine/HorizontalLine";

const Document = () => {
  const [degreeCount, setdegreeCount] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [showFormIntern, setShowFormIntern] = useState(false);
  const [showCVForm, setShowCVForm] = useState(false);
  const [showAadhaarForm, setShowAadhaarForm] = useState(false);
  const [showLORForm, setShowLORForm] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const inputDoc = useRef(null);
  const inputLang = useRef(null);

  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleLangClick = () => {
    inputLang.current.click();
  };

  const downloadDegreeList = async () => {
    let _degrees = await downloadDocuments();
    return _degrees;
  };

  const {
    data: data_degree,
    error: error_degree,
    isLoading: isLoading_degree,
  } = useQuery("data_degree", downloadDegreeList);

  const passport_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Passport");

  const cv_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "CV");
  const aadhaar_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Aadhaar");
  const lor_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "LOR");

  const createNewDoc = async (form) => {
    setLoading(true);
    try {
      await uploadFile(form);
      queryClient.invalidateQueries("data_degree");
      toast.success("Document uploaded successfully");
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setShowForm(false);
      setShowFormIntern(false);
      setShowCVForm(false);
      setLoading(false);
      setShowAadhaarForm(false);
      setShowLORForm(false);
    }
  };

  return (
    <div className="documents-main-container">
      <div className="lang-main-container">
        <p className="academics-heading">
          Passport{" "}
          <span style={{ fontSize: "10px", color: "grey" }}>
            (Max file size 2MB)
          </span>
        </p>
        {isLoading_degree
          ? `Loading`
          : passport_files.map((_degree, i) => {
            return <Travel type="Passport" data={_degree} key={i} />;
          })}

        <HorizontalLine
          showForm={showFormIntern}
          totalCards={passport_files.length}
        />

        {showFormIntern && (
          <TravelForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            type="Passport"
            isEdit={false}
            loading={isLoading}
            onCancel={() => {
              setShowFormIntern(false);
            }}
          />
        )}
        {!showFormIntern && (
          <div
            className="add_wrapper"
            onClick={() => {
              setShowFormIntern(true);
            }}
          >
            <img src={add} />
            Add Passport
          </div>)}
      </div>

      {/* <div className="lang-main-container">
        <div className="lang-container">
          <p className="academics-heading">
            CV{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>
          {isLoading_degree
            ? `Loading`
            : cv_files.map((_degree, i) => {
              return <Travel type="Passport" data={_degree} key={i} />;
            })}
        </div>

        <HorizontalLine showForm={showCVForm} totalCards={cv_files.length} />

        {showCVForm && (
          <TravelForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            type="CV"
            isEdit={false}
            onCancel={() => {
              setShowCVForm(false);
            }}
          />
        )}

        <div
          className="add_wrapper"
          onClick={() => {
            setShowCVForm(true);
          }}
        >
          <img src={add} />
          Add CV
        </div>
      </div> */}

      <div className="lang-main-container">
        <div className="lang-container">
          <p className="academics-heading">
            Aadhaar{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>
          {isLoading_degree
            ? `Loading`
            : aadhaar_files.map((_degree, i) => {
              return <Travel type="Aadhaar" data={_degree} key={i} />;
            })}
        </div>
        <HorizontalLine showForm={showAadhaarForm} totalCards={aadhaar_files.length} />

        {showAadhaarForm && (
          <TravelForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            type="Aadhaar"
            isEdit={false}
            onCancel={() => {
              setShowAadhaarForm(false);
            }}
          />
        )}
        {!showAadhaarForm && (
          <div
            className="add_wrapper"
            onClick={() => {
              setShowAadhaarForm(true);
            }}
          >
            <img src={add} />
            Add Aadhaar
          </div>)}
      </div>
      <div className="lang-main-container">
        <div className="lang-container">
          <p className="academics-heading">
            LOR{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>
          {isLoading_degree
            ? `Loading`
            : lor_files.map((_degree, i) => {
              return <Travel type="LOR" data={_degree} key={i} />;
            })}
        </div>
        <HorizontalLine showForm={showLORForm} totalCards={lor_files.length} />

        {showLORForm && (
          <TravelForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            type="LOR"
            isEdit={false}
            onCancel={() => {
              setShowLORForm(false);
            }}
          />
        )}
        {!showLORForm && (
          <div
            className="add_wrapper"
            onClick={() => {
              setShowLORForm(true);
            }}
          >
            <img src={add} />
            Add LOR
          </div>)}
      </div>
    </div>
  );
};

export default Document;
