import "../../profile.scss";
import { useEffect, useState, useContext, useRef } from "react";

import { AppContext } from "context/app_context";

import { getUser, saveUser } from "utils/local";
import { updateAbout } from "utils/api";

import selectArrowImage from "assets/svg/select-arrow.png";

import UploadContainer from "../common/UploadContainer";
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";
import SelectDate from "../common/SelectDate";
import { HorizontalLine } from "../common/HorizontalLine/HorizontalLine";

const TravelForm = (props) => {
  const { key, data, onSave, isEdit, onCancel, type, loading } = props;
  const { country, number, expiryDate } = data;
  const [doc, setDoc] = useState("");
  const [meta, setMeta] = useState({});
  const [aadhaarMeta, setAadhaarMeta] = useState({});
  const [lorMeta, setLORMeta] = useState({});
  const inputDoc = useRef(null);
  const [date, setDate] = useState(null);
  const [update, setUpdate] = useState(false);

  const [error, setError] = useState({
    country: "Required",
    number: "Required",
    date: "Required",
    doc: "Required",
  });

  const [cvupdate, setCVUpdate] = useState(false);
  const [aadhaarupdate, setAadhaarUpdate] = useState(false);
  const [lorupdate, setLORUpdate] = useState(false);
  const [cverror, setCVError] = useState({ doc: "Required" });
  const [aadhaarerror, setAadhaarError] = useState({ doc: "Required" });
  const [lorerror, setLORError] = useState({ doc: "Required" });

  useEffect(() => {
    setMeta({ country, number, expiryDate });
  }, [country, number, expiryDate, isEdit]);

  useEffect(() => {
    setAadhaarMeta({number});
  }, [number]);
  useEffect(() => {
    setAadhaarMeta({country});
  }, [country]);
  // useEffect(() => {
  //   console.log(meta);
  // }, [meta]);

  // useEffect(() => {
  //   console.log(error); // This will log the latest value
  // }, [error]);

  function isBeforeToday(dateString) {
    const inputDate = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return inputDate < today;
  }

  const handleCountry = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, country: "Required" });
    } else {
      setError({ ...error, country: "" });
    }
  };

  const handleNumber = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, number: "Please add the required details" });
    } else {
      setError({ ...error, number: "" });
    }
  };

  const truncate = () => {
    if (error.number) setMeta({ ...meta, ["number"]: "" });
  };

  const handleSave = () => {
    console.log(meta)
    if (Object.values(error).every((value) => value === "")) {
      const updateForm = new FormData();
      console.log(meta);
      updateForm.append("meta", JSON.stringify(meta));
      updateForm.append("type", type);
      if (doc) {
        updateForm.append("doc", doc);
      }
      onSave(updateForm);
    } else {
      truncate();
      setUpdate(true);
    }
  };

  const handleCVSave = () => {
    if (Object.values(cverror).every((value) => value === "")) {
      const updateForm = new FormData();
      console.log(meta);
      updateForm.append("meta", JSON.stringify(meta));
      updateForm.append("type", type);
      if (doc) {
        updateForm.append("doc", doc);
      }
      onSave(updateForm);
    } else {
      setCVUpdate(true);
    }
  };
  const handleAadhaarSave = () => {
    if (Object.values(aadhaarerror).every((value) => value === "")) {
      const updateForm = new FormData();
      console.log(aadhaarMeta);
      updateForm.append("meta", JSON.stringify(aadhaarMeta));
      updateForm.append("type", type);
      if (doc) {
        updateForm.append("doc", doc);
      }
      onSave(updateForm);
    } else {
      setAadhaarUpdate(true);
    }
  };
  const handleLORSave = () => {
    if (Object.values(lorerror).every((value) => value === "")) {
      const updateForm = new FormData();
      console.log(lorMeta);
      updateForm.append("meta", JSON.stringify(lorMeta));
      updateForm.append("type", type);
      if (doc) {
        updateForm.append("doc", doc);
      }
      onSave(updateForm);
    } else {
      setLORUpdate(true);
    }
  };
  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    if(type==="Aadhaar"){
      setAadhaarMeta({...aadhaarMeta, [name]: value});
    }
    if(type==="LOR"){
      setLORMeta({...lorMeta, [name]: value});
    }
    else{
      setMeta({ ...meta, [name]: value });
    }
  };

  return (
    <div>
      {type === "Passport" ? (
        <div className="education-main-container" key={key}>
          <form className="education-container">
            <section className="lang-inner">
              <>
                <div className="inner-left">
                  <div className="form-item">
                    <label htmlFor="">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      value={meta.country}
                      onChange={handleCountry}
                      style={
                        update && error.country
                          ? { border: "1px solid red", width: "auto" }
                          : { width: "auto" }
                      }
                    />
                    {update && error.country ? (
                      <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                        *{error.country}
                      </h6>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="cert-container">
                    <div className="form-item">
                      <label htmlFor="">{type} Number</label>
                      <input
                        type="text"
                        name="number"
                        id="passport"
                        value={meta.number}
                        onChange={handleNumber}
                        placeholder="Enter text"
                        style={
                          update && error.number
                            ? { border: "1px solid red", width: "auto" }
                            : { width: "auto" }
                        }
                      />
                      {update && error.number ? (
                        <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                          *{error.number}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="form-item">
                      <label htmlFor="">Expiry Date</label>
                      <SelectDate
                        date={date}
                        setDate={setDate}
                        error={error}
                        setError={setError}
                        update={update}
                        meta={meta}
                        type={"expiryDate"}
                      />
                      {update && error.date ? (
                        <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                          *{error.date}
                        </h6>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div className="inner-right">
                <UploadContainer
                  doc={doc}
                  setDoc={setDoc}
                  error={error}
                  setError={setError}
                  update={update}
                />
              </div>
            </section>
          </form>
          <div className="profile-buttons">
            <button
              className="btn-sec"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="btn-pri"
              onClick={handleSave}
              disabled={loading ? true : false}
            >
              {loading ? <Spinner color="white" /> : `Save`}
            </button>
          </div>
          <HorizontalLine />
        </div>
      ) :
      type === "Aadhaar" ? (
      <div className="education-main-container" key={key}>
        <form className="education-container">
          <section className="lang-inner">
            <>
              <div className="inner-left">
                <div className="form-item">
                  <label htmlFor="">Aadhaar Number</label>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    value={aadhaarMeta.number}
                    onChange={handleNumber}
                    style={
                      update && error.number
                        ? { border: "1px solid red", width: "auto" }
                        : { width: "auto" }
                    }
                  />
                  {update && error.number ? (
                    <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                      *{error.number}
                    </h6>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
            <div className="inner-right aadhaar">
              <UploadContainer
                doc={doc}
                setDoc={setDoc}
                error={aadhaarerror}
                setError={setAadhaarError}
                update={aadhaarupdate}
              />
            </div>
          </section>
        </form>
        <div className="profile-buttons">
          <button
            className="btn-sec"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </button>
          <button
            className="btn-pri"
            onClick={handleAadhaarSave}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner color="white" /> : `Save`}
          </button>
        </div>
        <HorizontalLine />
      </div>
      ): type === "LOR" ? (
        <div className="education-main-container" key={key}>
        <form className="education-container">
          <section className="lang-inner">
            <>
              <div className="inner-left">
                <div className="form-item">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    value={lorMeta.country}
                    onChange={handleNumber}
                    style={
                      update && error.number
                        ? { border: "1px solid red", width: "auto" }
                        : { width: "auto" }
                    }
                  />
                  {update && error.number ? (
                    <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                      *{error.number}
                    </h6>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
            <div className="inner-right aadhaar">
              <UploadContainer
                doc={doc}
                setDoc={setDoc}
                error={lorerror}
                setError={setLORError}
                update={lorupdate}
              />
            </div>
          </section>
        </form>
        <div className="profile-buttons">
          <button
            className="btn-sec"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </button>
          <button
            className="btn-pri"
            onClick={handleLORSave}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner color="white" /> : `Save`}
          </button>
        </div>
        <HorizontalLine />
      </div>
      ):
      ( ""
        // <div className="education-main-container" key={key}>
        //   <form className="education-container">
        //     <section className="lang-inner">
        //       <>
        //         <UploadContainer
        //           doc={doc}
        //           setDoc={setDoc}
        //           error={cverror}
        //           setError={setCVError}
        //           update={cvupdate}
        //           style={{ padding: "16px" }}
        //         />
        //       </>
        //     </section>
        //   </form>
        //   <div className="profile-buttons">
        //     <button
        //       className="btn-sec"
        //       onClick={() => {
        //         onCancel();
        //       }}
        //     >
        //       Cancel
        //     </button>
        //     <button
        //       className="btn-pri"
        //       onClick={handleCVSave}
        //       disabled={loading ? true : false}
        //     >
        //       {loading ? <Spinner color="white" /> : `Save`}
        //     </button>
        //   </div>
        //   <HorizontalLine />
        // </div>
      )}
    </div>
  );
};

export default TravelForm;
