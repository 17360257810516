import "./SkeletonEvents.scss";

const SkeletonEvents = () => {
  return (
    <div className="skeleton_event_container">
      <div className="skeleton skeleton_event_title"></div>
      <div className="skeleton skeleton_event_author"></div>
      <div className="skeleton_event_details">
        <div className="skeleton_event_details_section_one">
          <div className="skeleton skeleton_event_date"></div>
          <div className="skeleton skeleton_event_month"></div>
        </div>
        <div className="skeleton_event_details_section_two">
          <div className="skeleton skeleton_event_type"></div>
          <div className="skeleton skeleton_event_time"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonEvents;
