import { useState } from "react";

import "../common/Card.scss";

import doc from "assets/svg/ph_file.svg";
import doc_edit from "assets/svg/Kebab button.svg";

import { updateDoc, deleteDocs } from "utils/api";

import { toast } from "react-toastify";

import { queryClient } from "App";
import TravelForm from "./TravelForm";
import { DeleteConfirmationModal } from "../common/DeleteConfirmation/DeleteConfirmationModal";
import DropDown from "../common/DropDown/DropDown";

const Travel = (props) => {
  const { data, key, type, masterLoading } = props;
  const { docId, docUrl, docType, meta } = data;
  const { country, number, expiryDate } = JSON.parse(meta);
  const [isLoading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const saveDocUpdate = async (form) => {
    try {
      await updateDoc(docId, form);
      queryClient.invalidateQueries("data_degree");
      toast.success("Successfully updated");
    } catch (err) {
      toast.error("Something went wrong!");
    }

    setEditing(false);
    setModalVisible(false);
  };

  const handleDeleteDocClick = async () => {
    setIsOpen(true);
    setModalVisible(false);
  };

  function getFileName(url) {
    var urlObj = new URL(url);

    var pathComponents = urlObj.pathname.split("/");

    var fileName = pathComponents[pathComponents.length - 1];

    var decodedFileName = decodeURIComponent(fileName);

    return decodedFileName;
  }

  const handleDelete = async () => {
    try {
      await deleteDocs(docId);
      toast.success("Successfully deleted");
      queryClient.invalidateQueries("data_degree");
    } catch (err) {
      toast.error("Something went wrong!");
      console.log(err);
    }

    setIsOpen(false);
  };

  return editing ? (
    <TravelForm
      data={JSON.parse(meta)}
      docId={docId}
      onSave={saveDocUpdate}
      isEdit={editing}
      loading={masterLoading ? masterLoading : isLoading}
      type={type}
      onCancel={() => {
        setEditing(false);
        setModalVisible(false);
      }}
    />
  ) : (
    <div
      className="contain_language"
      key={key}
      onClick={() => {
        setModalVisible(false);
      }}
    >
      {type === "Passport" ? 
      (<div className="left-contain">
        <div className="complete-left">
          <p className="degree-name">{country || ""}</p>
          <p
            className="college-name"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "0.8rem",
            }}
          >
            {type === "Passport" ? (
              <div>
                {number && (
                  <p>
                    <span
                      style={{
                        color: "black",
                        fontWeight: "600",
                        paddingRight: "0.5rem",
                      }}
                    >
                      Valid through:
                    </span>{" "}
                    {expiryDate}
                  </p>
                )}
              </div>
            ) : null}
          </p>
        </div>
        <img
          className="mobile_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>) : type === "Aadhaar" ? 
      (<div className="left-contain">
        <div className="complete-left">
          <p className="degree-name">{"Aadhaar Number" || ""}</p>
          <p
            className="college-name"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "0.8rem",
            }}
          >
            {number}
          </p>
        </div>
        <img
          className="mobile_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>): (<div className="left-contain">
        <div className="complete-left">
          <p className="degree-name">{country || ""}</p>
          <p
            className="college-name"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "0.8rem",
            }}
          >
          </p>
        </div>
        <img
          className="mobile_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>)}
      {/* <div className="left-contain">
        <div className="complete-left">
          <p className="degree-name">{country || ""}</p>
          <p
            className="college-name"
            style={{
              color: "var(--text-grey-600, #888C92)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "0.8rem",
            }}
          >
            {type === "Passport" ? (
              <div>
                {number && (
                  <p>
                    <span
                      style={{
                        color: "black",
                        fontWeight: "600",
                        paddingRight: "0.5rem",
                      }}
                    >
                      Valid through:
                    </span>{" "}
                    {expiryDate}
                  </p>
                )}
              </div>
            ) : null}
          </p>
        </div>
        <img
          className="mobile_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div> */}

      <div className="right-side">
        <div
          className="wrap_section"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(docUrl);
          }}
        >
          <div className="right-contain">
            <img src={doc} alt="" />
            <div className="file_name">
              <p className="filename">
                {getFileName(docUrl)}
              </p>
              {/* <p className="filesize">{(file.size / (1024 * 1024)).toFixed(2)}MB</p> */}
            </div>
          </div>
        </div>
        <img
          className="desktop_kebab"
          src={doc_edit}
          style={{ cursor: "pointer", width: "24px" }}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        />
      </div>

      <DeleteConfirmationModal
        title="Document"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleDelete={handleDelete}
      />

      {modalVisible && (
        <DropDown
          setEditing={setEditing}
          handleDeleteDocClick={handleDeleteDocClick}
        />
      )}
    </div>
  );
};

export default Travel;
