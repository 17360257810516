import React, {useState } from 'react';
import axios from 'axios';
import "./signup.css";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from "../../context/app_context";
import { signUp } from '../../utils/api';
const SignUp = () => {

  const [form,setForm] = useState({});
  const {showAlert} = useContext(AppContext);
  const navigate = useNavigate();

  const handleInputChange = ({target})=>{
    const {name,value} = target;
    setForm({
      ...form,
      [name]: value
    })
  }
  const handleSubmit = async({target})=>{
    try {
      let response = await signUp(form);
      console.log(response);
      navigate('/');
    } catch (error) {
      console.log(error)
    }
  }

  

  return (
    <div className="login_main_wrapper">
      <div className="login_container">
        <div className="title">Create Your Account</div>

        <div className="input_container">
          <div>Email</div>
          <input type="email" value={form["username"]} name="username" placeholder="Email"
          onChange={handleInputChange}/>
        </div>

        <div className="input_container">
          <div>Password</div>
          <input type="password" value={form["password"]} name="password" placeholder="Password"
          onChange={handleInputChange}/>
        </div>

        <div className="button_container">
          <button onClick={handleSubmit}>Create Account</button>
        </div>

        <div className="existing_user_container">
          I’m already a user. <a href="/">Sign In</a>
        </div>

        <div className="terms_container">
          By creating account you accept our <a href="/policy">Terms of use </a>and <a href="/policy">Privacy Policy</a>.
        </div>

        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default SignUp;
