import "./SkeletonOverview.scss";

const SkeletonOverview = () => {
  return (
    <div className="skeleton_overview_container">
      <div className="skeleton_title_overview">Overview</div>
      <div className="skeleton_overview_inner">
        <div className="skeleton_frame">
          <div className="skeleton skeleton_frame_title"></div>
          <div className="skeleton_frame_label">Applied Universites</div>
        </div>
        <div className="skeleton_frame">
          <div className="skeleton skeleton_frame_title"></div>
          <div className="skeleton_frame_label">Applied Courses</div>
        </div>
        <div className="skeleton_frame">
          <div className="skeleton skeleton_frame_title">&nbsp;</div>
          <div className="skeleton_frame_label">Expected Intake</div>
        </div>
        <div className="skeleton_frame desktop-only">
          <div className="skeleton skeleton_frame_title"></div>
          <div className="skeleton_frame_label desktop-only">Admits</div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonOverview;
