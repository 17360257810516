import "./forgot.css";
const ForgotPassword = () => {
  return (
    <div className="login_main_wrapper">
      <div className="login_container">
        <div className="title">Forgot Password?</div>
        <div className="forgot_sub_title">
        <div>Enter Email associalted with your linked account, We’ll sent you a reset password link</div>
        </div>
        <div className="input_container">
          <div>Email</div>
          <input type="text" placeholder="Email"/>
        </div>
        <div className="button_container">
          <button>Sent</button>
        </div>
        <div className="existing_user_container">
          <a href="/">&#x2190; Back to Login</a>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default ForgotPassword;
