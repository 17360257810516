import "../profile.scss";
import add from "assets/svg/Frame 2.svg";
import { MdKebabDining, MdOutlineFileDownload } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";
import { useState, useRef } from "react";
import { downloadDocuments, uploadFile } from "utils/api";
import Kebab from "../../../../../assets/svg/Kebab.svg";
import { useQuery } from "react-query";
import { queryClient } from "App";
import TravelForm from "./travel/TravelForm";
import Travel from "./travel/TravelCard";
import Admin from "./admin/AdminCard";
import { toast } from "react-toastify";
import { HorizontalLine } from "./common/HorizontalLine/HorizontalLine";

const AdminUploads = () => {
    const [degreeCount, setdegreeCount] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [showFormIntern, setShowFormIntern] = useState(false);
    const [showCVForm, setShowCVForm] = useState(false);
    const [showAadhaarForm, setShowAadhaarForm] = useState(false);
    const [showLORForm, setShowLORForm] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [docs, setDocs] = useState({});

    const downloadDegreeList = async () => {
        let _degrees = await downloadDocuments();
        setDocs(_degrees);
        console.log("doc data", docs);
        return _degrees;
    };

    const truncateFileName = (fileName, maxLength) => {
        if (fileName.length <= maxLength) {
            return fileName;
        } else {
            return fileName.substring(0, maxLength - 3) + '...';
        }
    };

    function getFileName(url) {
        var urlObj = new URL(url);

        var pathComponents = urlObj.pathname.split("/");

        var fileName = pathComponents[pathComponents.length - 1];

        var decodedFileName = decodeURIComponent(fileName);

        return decodedFileName;
    };

    const {
        data: data_degree,
        error: error_degree,
        isLoading: isLoading_degree,
    } = useQuery("data_degree", downloadDegreeList);

    const visa_files = isLoading_degree
        ? []
        : data_degree.filter((_degree) => _degree.doctype === "visa");
    const career_files = isLoading_degree
        ? []
        : data_degree.filter((_degree) => _degree.doctype === "career");
    const blocked_files = isLoading_degree
        ? []
        : data_degree.filter((_degree) => _degree.doctype === "blocked");
    const referral_files = isLoading_degree
        ? []
        : data_degree.filter((_degree) => _degree.doctype === "referral");


    return (
        <div className="documents-main-container">
            <div className="lang-main-container">
            <div className="lang-container">
            <p className="academics-heading">
                Visa Documents{" "}
                <span style={{ fontSize: "10px", color: "grey" }}>
                </span>
            </p>
            {isLoading_degree
                ? `Loading`
                : visa_files.map((_degree, i) => {
                return <Admin type="Visa" data={_degree} key={i} />;
                })}
            </div>
            </div>

            <div className="lang-main-container">
            <div className="lang-container">
            <p className="academics-heading">
                Career Documents{" "}
                <span style={{ fontSize: "10px", color: "grey" }}>
                </span>
            </p>
            {isLoading_degree
                ? `Loading`
                : career_files.map((_degree, i) => {
                return <Admin type="Career" data={_degree} key={i} />;
                })}
            </div>


            </div>
            <div className="lang-main-container">
            <div className="lang-container">
            <p className="academics-heading">
                Blocked Accounts{" "}
                <span style={{ fontSize: "10px", color: "grey" }}>
                </span>
            </p>
            {isLoading_degree
                ? `Loading`
                : blocked_files.map((_degree, i) => {
                return <Admin type="Blocked" data={_degree} key={i} />;
                })}
            </div>

            </div>
            <div className="lang-main-container">
            <div className="lang-container">
            <p className="academics-heading">
                Referral Links{" "}
                <span style={{ fontSize: "10px", color: "grey" }}>
                </span>
            </p>
            {isLoading_degree
                ? `Loading`
                : referral_files.map((_degree, i) => {
                return <Admin type="Referral" data={_degree} key={i} />;
                })}
            </div>

            </div>
        </div>
    );
};

export default AdminUploads;
