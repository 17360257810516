import MenuItem from "../menu_item/menu_item";

import "react-toastify/dist/ReactToastify.css";
import "../../dashboard.scss";
import "./profile.scss";

import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import Details from "./components/details";
import Experience from "./components/experience";
import Document from "./components/documents";
import Education from "./components/education";
import AdminUploads from "./components/admin";

import { AppContext } from "context/app_context";

import { updateAbout, updateUser } from "utils/api";
import { getUser, saveUser } from "utils/local";

import { Spinner } from "@chakra-ui/react";
import Arrow_left from "assets/svg/Arrow_left.png";
import selectArrowImage from "assets/svg/select-arrow.png";
import LTALogo from "assets/images/lta_logo.svg";
import DP from "assets/images/dp.png";
import editIcon from "assets/svg/edit.svg";

import Select from "react-select";
import { ToastContainer } from "react-toastify";


const Profile = () => {
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("ACTIVE_TAB") || "1"
  ); // ["dashboard", "profile", "documents", "settings"

  const navigate = useNavigate();
  const goto = useNavigate();

  const { user, setUser } = useContext(AppContext);
  const [showOverlay, setOverlay] = useState(false);
  const inputRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [file, setFile] = useState(null);
  const [aboutForm, setAboutForm] = useState({});
  const [imageDataUrl, setImageDataUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name_first: "",
    name_last: "",
    phone: "",
  });

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    loadUserFromLocal();
  }, []);

  const loadUserFromLocal = () => {
    let _user = getUser();
    if (_user) {
      _user = JSON.parse(_user);

      setFormData({
        name_first: _user.name_first || _user.firstName,
        name_last: _user.name_last || _user.lastName,
        phone: _user.phone,
      });
      setAboutForm({
        expectedIntake: _user.expectedIntake,
        typeOfApplication: _user.typeOfApplication,
      });
    }
    setUser(_user);
  };

  const handleSelectedInput = (ID) => {
    setSelectedInput(ID);
  };

  const divStyle = {
    backgroundImage: `url(${user.dp})`,
  };

  const openModal = () => {
    // document.querySelector(".main_wrapper").style.opacity = "0.7";
    setModalVisible(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      setImageDataUrl(imageUrl);
    };

    reader.readAsDataURL(selectedFile);
    setFile(selectedFile);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    if (formData.name_first) {
      data.append("name_first", formData.name_first);
    }
    if (formData.name_last) {
      data.append("name_last", formData.name_last);
    }
    if (formData.phone) {
      data.append("phone", formData.phone);
    }
    if (file) {
      data.append("dp", file);
    }


    try {
      if (Object.keys(aboutForm).length > 0) {
        let response = await updateAbout(aboutForm);
      }
      if (
        formData.name_first ||
        formData.name_last ||
        formData.phone ||
        imageDataUrl
      ) {
        let response = await updateUser(data);
        saveUser(JSON.stringify(response));
        setUser(response);
      }
      setLoading(false);
      setModalVisible(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleChangeAbout = ({ target }) => {
    const { name, value } = target;
    setAboutForm({ ...aboutForm, [name]: value });
  };

  const handleApplicationChange = (selectedOption) => {
    if (selectedOption) {
      setAboutForm({ ...aboutForm, typeOfApplication: selectedOption.value });
    }
  };

  const handleIntakeChange = (selectedOption) => {
    if (selectedOption) {
      setAboutForm({ ...aboutForm, expectedIntake: selectedOption.value });
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "8px 16px",
      fontSize: "14px",
      fontFamily: "Inter",
      border: "1px solid #b5beca",
      borderRadius: "8px",
      color: "#35465a",
      height: "40px",
      opacity: "0.67",
      fontWeight: "400",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      paddingRight: "20px",
      alignItems: "center",
      justifyContent: "center",
      background: `url(${selectArrowImage}) no-repeat right center`,
      outline: state.isFocused && "2px solid #8764DE",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "#8764DE" : provided.background,
      color: state.isFocused ? "white" : "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
    }),
    valueContainer: (provided) => ({
      ...provided,
      alignItems: "start",
      display: "flex",
      padding: "0px",
    }),
  };

  return (
    <section
      className="main_wrapper"
      onClick={() => {
        setOverlay(false);
      }}
    >
      <ToastContainer />
      <nav className="nav_container">
        <img src={LTALogo} alt="Logo main" className="lta_logo" />
        <div className="right_nav">
          <div className="profile">
            <p className="username">
              {user.firstName || user.first_name}{" "}
              {user.lastName || user.last_name}
            </p>
            <div
              className="dp"
              onClick={(e) => {
                e.stopPropagation();
                setOverlay(!showOverlay);
              }}
            >
              <img
                src={user.dp || user.profile_image || DP}
                alt="DP"
                className="profile-nav-img"
              />
            </div>
            {showOverlay ? (
              <div className="profile_overlay">
                <div className="name">{`${user.firstName} ${user.lastName}`}</div>
                <div className="username">{user.username}</div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "darkred",
                    borderRadius: "6px",
                    padding: "4px 8px 8px 8px",
                  }}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Logout
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </nav>

      <div className="body_container" id="body-container">
        <div className="dash_sidebar" id="dash-sidebar">
          <MenuItem itemName="dashboard" active={false} />
          {/* <MenuItem itemName="profile" active={true} /> */}
          <MenuItem itemName="profile" active={true} />
          {/* <MenuItem itemName="settings" active={false} /> */}
        </div>
        <div className="profile-buttons-home">
          <button className="btn-sec">Cancel</button>
          <button className="btn-pri">Save</button>
        </div>

        <div className="profile-main-container">
          <div className="profile-container">
            <div
              className="profile-image-container"
              onClick={() => openModal()}
              style={divStyle}
            >
              <img
                src={imageDataUrl || user.dp || user.profile_image || DP}
                alt="user profile"
              />
            </div>

            <div className="profile-main">
              <div className="profile-name">
                <p>{`${user.firstName || user.name_first} ${user.lastName || user.name_last
                  }`}</p>
              </div>
              <div style={{ marginRight: "4vw", marginLeft: "2vw" }}>
                <div id="profile-contact">
                  <div>{user.username}</div>
                  <div>{user.phone}</div>
                </div>
              </div>

              <div className="details">
                <div className="details-content">
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      letterSpacing: "0.015em",
                      textAlign: "left",
                    }}
                  >
                    Type of Application
                  </div>
                  {user.typeOfApplication ? (
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      {user.typeOfApplication}
                    </p>
                  ) : (
                    <p className="_color" onClick={() => openModal()}>
                      Add now
                    </p>
                  )}
                </div>
                <div className="details-content">
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "15px",
                      letterSpacing: "0.015em",
                      textAlign: "left",
                    }}
                  >
                    Expecting Intake
                  </div>
                  {user.expectedIntake ? (
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      {user.expectedIntake}
                    </p>
                  ) : (
                    <p className="_color" onClick={() => openModal()}>
                      Add now
                    </p>
                  )}
                </div>
              </div>

              <button className="edit-button" onClick={() => openModal()}>
                <img src={editIcon} alt="" />
                Edit Profile
              </button>
            </div>

            {modalVisible && (
              <div className="modal">
                <div className="modal-content">
                  <p>Edit Profile</p>

                  <div className="modal-update">
                    <div className="update-left">
                      <p style={{ padding: "10px" }}>Profile Picture</p>
                      <div className="update-dp" onClick={handleImageClick}>
                        <img
                          src={
                            imageDataUrl || user.dp || user.profile_image || DP
                          }
                          alt=""
                        />
                        <input
                          type="file"
                          name="profile_img"
                          id="profile_img"
                          onChange={handleFileChange}
                          ref={inputRef}
                          style={{ display: "none" }}
                        />
                        <div className="edit-button-container">
                          <button className="edit-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16" stroke="white">
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="update-right">
                      <div className="right-inner" style={{ padding: "10px" }}>
                        <p>First Name</p>
                        <input
                          type="text"
                          name="name_first"
                          id="f_name"
                          placeholder="Enter First Name"
                          value={formData.name_first}
                          onChange={handleInputChange}
                          className={
                            selectedInput === "f_name" ? "selected" : ""
                          }
                          onClick={() => handleSelectedInput("f_name")}
                        />
                      </div>
                      <div className="right-inner" style={{ padding: "10px" }}>
                        <p>Last Name</p>
                        <input
                          type="text"
                          name="name_last"
                          id="l_name"
                          placeholder="Enter Last Name"
                          value={formData.name_last}
                          onChange={handleInputChange}
                          className={
                            selectedInput === "l_name" ? "selected" : ""
                          }
                          onClick={() => handleSelectedInput("l_name")}
                        />
                      </div>
                      <div className="right-inner" style={{ padding: "10px" }}>
                        <p>Phone Number</p>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="+91 9999 9999"
                          value={formData.phone}
                          onChange={handleInputChange}
                          className={
                            selectedInput === "phone" ? "selected" : ""
                          }
                          onClick={() => handleSelectedInput("phone")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="line-break"></div>
                  <div className="modal-options">
                    <label htmlFor="application_type">
                      Type of Application
                    </label>
                    <Select
                      id="typeOfApplication"
                      name="application_type"
                      options={[
                        { value: "Masters", label: "Masters" },
                        { value: "Ausbildung", label: "Ausbildung" },
                        { value: "Bachelors", label: "Bachelors" },
                        { value: "FSJ", label: "FSJ" },
                      ]}
                      value={{
                        value: aboutForm.typeOfApplication,
                        label: aboutForm.typeOfApplication,
                      }}
                      onChange={handleApplicationChange}
                      styles={{
                        ...customStyles,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary50: "#ffffff",
                          primary25: "hotpink",
                          primary: "#8764DE19",
                        },
                      })}
                    />
                  </div>
                  <div className="modal-options">
                    <label htmlFor="intake">Expected Intake</label>
                    <Select
                      id="intake"
                      name="expectedIntake"
                      options={[
                        { value: "Summer", label: "Summer" },
                        { value: "Winter", label: "Winter" },
                      ]}
                      value={{
                        value: aboutForm.expectedIntake,
                        label: aboutForm.expectedIntake,
                      }}
                      onChange={handleIntakeChange}
                      styles={{
                        ...customStyles,
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary50: "#ffffff",
                          primary25: "hotpink",
                          primary: "#8764DE19",
                        },
                      })}
                    />
                  </div>

                  <div className="profile-buttons">
                    <button className="btn-sec" onClick={closeModal}>
                      Cancel
                    </button>
                    <button className="btn-pri" onClick={handleSave}>
                      {isLoading ? <Spinner color="white" /> : `Save`}
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="doc_mob" onClick={() => goto("/Dashboard")}>
              <img src={Arrow_left} alt="" />
              <div>Documents</div>
            </div>
          </div>

          <div className="profile-nav">
            <div className="profile-nav-inner">
              <span
                className={`nav_item ${activeTab === "1" ? `activetab` : ``}`}
                onClick={() => {
                  handleActiveTab("1");
                  localStorage.setItem("ACTIVE_TAB", "1");
                }}
              >
                Personal details
              </span>
              <span
                className={`nav_item ${activeTab === "2" ? `activetab` : ``}`}
                onClick={() => {
                  handleActiveTab("2");
                  localStorage.setItem("ACTIVE_TAB", "2");
                }}
              >
                Education Documents
              </span>
              <span
                className={`nav_item ${activeTab === "3" ? `activetab` : ``}`}
                onClick={() => {
                  handleActiveTab("3");
                  localStorage.setItem("ACTIVE_TAB", "3");
                }}
              >
                Work Experience
              </span>
              <span
                className={`nav_item ${activeTab === "4" ? `activetab` : ``}`}
                onClick={() => {
                  handleActiveTab("4");
                  localStorage.setItem("ACTIVE_TAB", "4");
                }}
              >
                Documents
              </span>
              <span
                className={`nav_item ${activeTab === "5" ? `activetab` : ``}`}
                onClick={() => {
                  handleActiveTab("5");
                  localStorage.setItem("ACTIVE_TAB", "5");
                }}
              >
                Admin Uploads
              </span>
              {/* <span className={`nav_item ${activeTab ==="5"?`activetab`:``}`} onClick={()=>{handleActiveTab("5");localStorage.setItem("ACTIVE_TAB","5");}}>Study Plans</span> */}
            </div>
          </div>

          <div
            className="profile-details"
            style={{ display: activeTab === "1" ? "flex" : "none" }}
          >
            <div id="personal-content">
              <span className="content-head">Personal details</span>
              <div className="content-body">
                Enter personal details abour yourself and ensure the input is
                correct as well
              </div>
            </div>
            <Details />
          </div>

          <div
            className="profile-details"
            style={{ display: activeTab === "2" ? "flex" : "none" }}
          >
            <div id="personal-content">
              <span className="content-head">Education Documents</span>
              <div className="content-body">
                Enter and Upload your education details and required documents
              </div>
            </div>
            <Education />
          </div>

          <div
            className="profile-details"
            style={{ display: activeTab === "3" ? "flex" : "none" }}
          >
            <div id="personal-content">
              <span className="content-head">Employment Documents</span>
              <div className="content-body">
                Enter and Upload your employment details and required documents
              </div>
            </div>
            <Experience />
          </div>

          <div
            className="profile-details"
            style={{ display: activeTab === "4" ? "flex" : "none" }}
          >
            <div id="personal-content">
              <span className="content-head">Travel Documents</span>
              <div className="content-body">
                Enter and Upload your travel details and required documents
              </div>
            </div>
            <Document />
          </div>
          <div
            className="profile-details"
            style={{ display: activeTab === "5" ? "flex" : "none" }}
          >
            <div id="personal-content">
              <span className="content-head">Processed Documents</span>
              <div className="content-body">
                These are documents that already processed and are uploaded by the administrator
              </div>
            </div>
            <AdminUploads />
          </div>
        </div>
      </div>
      {/* <div className="profile-buttons-home">
                    <button className="btn-sec">Cancel</button>
                    <button className="btn-pri">Save</button>
                </div> */}
    </section>
  );
};

export default Profile;
