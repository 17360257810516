import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUp from "./screens/signup";
import SignIn from "./screens/signin";
import ForgotPassword from "./screens/forgot";
import Dashboard from "./screens/dashboard";
import PrivateRoute from "./private_route/private_route";
import Profile from "./screens/dashboard/components/profile/index";

const LetterToAbroad = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route exact path="/" element={<SignIn />} />
        <Route exact path="/login" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot" element={<ForgotPassword />} />
        <Route exact path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
};
export default LetterToAbroad;
