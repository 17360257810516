import "../profile.scss";
import { useEffect, useState, useContext } from "react";

import { AppContext } from "context/app_context";

import { getUser, saveUser } from "utils/local";
import { updateAbout } from "utils/api";

import selectArrowImage from "assets/svg/select-arrow.png";
import add from "assets/svg/Frame 2.svg";

import Select from "react-select";
import { toast } from "react-toastify";
import { Spinner } from "@chakra-ui/react";

import SelectDate from "./common/SelectDate";
import { HorizontalLine } from "./common/HorizontalLine/HorizontalLine";

const Details = () => {
  const [update, setUpdate] = useState(false);
  const [addressCount, setaddressCount] = useState(1);
  const [addressUpdate, setAddressUpdate] = useState([]);
  const { user, setUser } = useContext(AppContext);
  const [meta, setMeta] = useState({});
  const [date, setDate] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState({
    date: "Required",
    citizenship: "Required",
    gender: "Required",
  });

  const [formData, setFormData] = useState({
    gender: "",
    dob: "",
    citizenship: "",
  });
  const [selectedInput, setSelectedInput] = useState(null);

  const handleSelectedInput = (name) => (e) => {
    setSelectedInput(name);
  };

  useEffect(() => {
    renderPreExistingValues();
  }, []);

  // useEffect(() => {
  //   console.log(formData, error);
  // }, []);

  useEffect(() => {
    setFormData({
      gender: user.gender,
      dob: user.dob,
      citizenship: user.citizenship,
      addresses: user.addresses,
    });
    setAddressUpdate(user.addresses);
  }, [user]);

  const renderPreExistingValues = () => {
    let _user = getUser();
    _user = JSON.parse(_user);
    setUser(_user);
    const { addresses, dob } = _user;
    setFormData({ ...formData, dob });
    if (addressUpdate.length === 0) {
      setAddressUpdate(addresses);
      if (addresses.length > 1) {
        setaddressCount(2);
      }
    }
  };

  function isAtLeast18YearsOld(dateString) {
    const dob = new Date(dateString);
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return dob <= eighteenYearsAgo;
  }

  const handleDOB = (e) => {
    handleInputChange(e);
    if (!e.target.value) {
      setError({ ...error, dob: "Required" });
    } else {
      setError({ ...error, dob: "" });
    }
  };

  const handleCitizenship = (e) => {
    handleInputChange(e);
    if (!e.target.value) {
      setError({ ...error, citizenship: "Required" });
    } else {
      setError({ ...error, citizenship: "" });
    }
  };

  const handleGender = (e) => {
    handleGenderChange(e);
    if (!e.value) {
      setError({ ...error, gender: "Required" });
    } else {
      setError({ ...error, gender: "" });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleGenderChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        gender: selectedOption.value,
      });
    }
  };

  const handleAddressChange = (index, value) => {
    const newAddress = [...addressUpdate];
    newAddress[index] = value;
    setAddressUpdate(newAddress);
  };

  const handleSave = async (e) => {
    if (Object.values(error).every((value) => value === "")) {
      setLoading(true);
      e.preventDefault();
      formData["addresses"] = addressUpdate;
      formData["dob"] = meta.dob;
      try {
        let response = await updateAbout(formData);
        toast.success("Successfully updated");

        saveUser(JSON.stringify(response));
        setUser(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong!");

        setLoading(false);
      }
    } else {
      setUpdate(true);
    }
  };

  const handleDateChange = (date) => {
    // Update your form data with the selected date
    setFormData({
      ...formData,
      dob: date,
    });
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Inter",
      border: update && error.gender ? "1px solid red" : "1px solid #b5beca",
      borderRadius: "10px",
      color: "#35465a",
      height: "40px",
      fontWeight: "400",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      paddingRight: "20px",
      alignItems: "center",
      justifyContent: "center",
      background: `url(${selectArrowImage}) no-repeat right center`,
      outline: state.isFocused && "2px solid #8764DE",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#8764DE" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#8764DE" : "#D5C8EF",
      },
    }),
    ValueContainer: (provided) => ({
      ...provided,
      alignItems: "start",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "2px",
      paddingLeft: "6px",
    }),
    singleValue: (provided) => ({
      ...provided,
      order: 1,
      color: "#35465a",
    }),
    Input: (provided) => ({
      ...provided,
      paddingLeft: "6px !important",
      order: 2,
      "& input": {
        paddingLeft: "6px",
      },
    }),
  };

  //validation 
  useEffect(() => {
    setFormData({
      gender: user.gender || "",
      dob: user.dob || "",
      citizenship: user.citizenship || "",
      addresses: user.addresses || [],
    });

    setError({
      date: user.dob ? "" : "Required",
      citizenship: user.citizenship ? "" : "Required",
      gender: user.gender ? "" : "Required",
    });
  }, [user]);


  return (
    <div className="details-form">
      <div className="form-left">
        <div className="form-item">
          <label htmlFor="">DOB</label>
          <SelectDate
            date={date}
            setDate={setDate}
            error={error}
            setError={setError}
            update={update}
            meta={meta}
            type={"dob"}
          />
          {update && error.date ? (
            <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
              *{error.date}
            </h6>
          ) : (
            <></>
          )}
        </div>

        <div className="form-item">
          <label htmlFor="">Citizenship</label>
          <input
            type="text"
            name="citizenship"
            id="citizenship"
            onChange={handleCitizenship}
            value={formData["citizenship"]}
            placeholder="Enter Text"
            style={
              update && error.citizenship
                ? { border: "1px solid red", width: "auto" }
                : { width: "auto" }
            }
          />
          {update && error.citizenship ? (
            <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
              *{error.citizenship}
            </h6>
          ) : (
            <></>
          )}
        </div>
        <div className="form-item">
          <label htmlFor="gender">Gender</label>
          <Select
            id="gender"
            name="gender"
            placeholder={<div>Select Gender</div>}
            options={[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
            ]}
            value={
              formData.gender
                ? { value: formData.gender, label: formData.gender }
                : { value: "", label: "Select your Gender" }
            }
            onChange={handleGender}
            styles={{
              ...customStyles,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary50: "#ffffff",
                primary25: "hotpink",
                primary: "#8764DE19",
              },
            })}
          />
          {update && error.gender ? (
            <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
              *{error.gender}
            </h6>
          ) : (
            <></>
          )}
        </div>
      </div>
      <HorizontalLine></HorizontalLine>
      <div className="form-right">
        <div className="form-item form-address">
          <label htmlFor="">Address 1</label>
          <div className="address-container">
            <textarea
              name="address"
              id="address"
              value={addressUpdate?.[0] || ""}
              onChange={(e) => handleAddressChange(0, e.target.value)}
              cols="54"
              rows="10"
              placeholder="Enter Text"
            ></textarea>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                addressUpdate.splice(0, 1);
                setaddressCount((count) => count - 1);
              }}
            >
              {" "}
            </div>
          </div>
        </div>
        <div
          className="add-address"
          onClick={() => {
            setaddressCount(2);
          }}
          style={{
            display:
              addressCount === 2 ? "none" : addressCount < 2 ? "flex" : <></>,
          }}
        >
          <img src={add} alt="" />
          Add Address
        </div>
      </div>

      <div
        className="address-2"
        style={{
          display: addressCount === 2 || addressCount > 2 ? "block" : "none",
        }}
      >
        <div className="form-right">
          <div className="form-item" style={{ marginTop: 20 }}>
            <label htmlFor="">Address 2</label>
            <div
              className="address-container"
              style={{ display: "flex", gap: 10 }}
            >
              <textarea
                name="address"
                id="address"
                value={addressUpdate?.[1] || ""}
                onChange={(e) => handleAddressChange(1, e.target.value)}
                cols="62"
                rows="10"
                placeholder="Enter Text"
              ></textarea>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  addressUpdate.splice(-1);
                  setaddressCount((count) => count - 1);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M15.1305 4.4375C15.3294 4.4375 15.5202 4.51652 15.6608 4.65717C15.8015 4.79782 15.8805 4.98859 15.8805 5.1875C15.8805 5.38641 15.8015 5.57718 15.6608 5.71783C15.5202 5.85848 15.3294 5.9375 15.1305 5.9375H14.3805L14.3782 5.99075L13.6785 15.794C13.6516 16.1724 13.4822 16.5266 13.2046 16.7852C12.9269 17.0438 12.5616 17.1875 12.1822 17.1875H6.07799C5.6986 17.1875 5.33329 17.0438 5.05566 16.7852C4.77802 16.5266 4.60868 16.1724 4.58174 15.794L3.88199 5.9915C3.88086 5.97352 3.88036 5.95551 3.88049 5.9375H3.13049C2.93158 5.9375 2.74082 5.85848 2.60016 5.71783C2.45951 5.57718 2.38049 5.38641 2.38049 5.1875C2.38049 4.98859 2.45951 4.79782 2.60016 4.65717C2.74082 4.51652 2.93158 4.4375 3.13049 4.4375H15.1305ZM12.8782 5.9375H5.38274L6.07874 15.6875H12.1822L12.8782 5.9375ZM10.6305 2.1875C10.8294 2.1875 11.0202 2.26652 11.1608 2.40717C11.3015 2.54782 11.3805 2.73859 11.3805 2.9375C11.3805 3.13641 11.3015 3.32718 11.1608 3.46783C11.0202 3.60848 10.8294 3.6875 10.6305 3.6875H7.63049C7.43158 3.6875 7.24082 3.60848 7.10016 3.46783C6.95951 3.32718 6.88049 3.13641 6.88049 2.9375C6.88049 2.73859 6.95951 2.54782 7.10016 2.40717C7.24082 2.26652 7.43158 2.1875 7.63049 2.1875H10.6305Z"
                      fill="#BEBEBE"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div
            className="add-address"
            onClick={() => {
              setaddressCount(3);
            }}
            style={{
              display:
                addressCount === 3 ? "none" : addressCount < 3 ? "flex" : <></>,
            }}
          >
            <img src={add} alt="" />
            Add Address
          </div>
        </div>
      </div>
      <div
        className="address-3"
        style={{
          display: addressCount === 3 || addressCount > 3 ? "block" : "none",
        }}
      >
        <div className="form-right">
          <div className="form-item" style={{ marginTop: 20 }}>
            <label htmlFor="">Address 3</label>
            <div
              className="address-container"
              style={{ display: "flex", gap: 10 }}
            >
              <textarea
                name="address"
                id="address"
                value={addressUpdate?.[1] || ""}
                onChange={(e) => handleAddressChange(1, e.target.value)}
                cols="62"
                rows="10"
                placeholder="Enter Text"
              ></textarea>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  addressUpdate.splice(-1);
                  setaddressCount((count) => count - 1);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M15.1305 4.4375C15.3294 4.4375 15.5202 4.51652 15.6608 4.65717C15.8015 4.79782 15.8805 4.98859 15.8805 5.1875C15.8805 5.38641 15.8015 5.57718 15.6608 5.71783C15.5202 5.85848 15.3294 5.9375 15.1305 5.9375H14.3805L14.3782 5.99075L13.6785 15.794C13.6516 16.1724 13.4822 16.5266 13.2046 16.7852C12.9269 17.0438 12.5616 17.1875 12.1822 17.1875H6.07799C5.6986 17.1875 5.33329 17.0438 5.05566 16.7852C4.77802 16.5266 4.60868 16.1724 4.58174 15.794L3.88199 5.9915C3.88086 5.97352 3.88036 5.95551 3.88049 5.9375H3.13049C2.93158 5.9375 2.74082 5.85848 2.60016 5.71783C2.45951 5.57718 2.38049 5.38641 2.38049 5.1875C2.38049 4.98859 2.45951 4.79782 2.60016 4.65717C2.74082 4.51652 2.93158 4.4375 3.13049 4.4375H15.1305ZM12.8782 5.9375H5.38274L6.07874 15.6875H12.1822L12.8782 5.9375ZM10.6305 2.1875C10.8294 2.1875 11.0202 2.26652 11.1608 2.40717C11.3015 2.54782 11.3805 2.73859 11.3805 2.9375C11.3805 3.13641 11.3015 3.32718 11.1608 3.46783C11.0202 3.60848 10.8294 3.6875 10.6305 3.6875H7.63049C7.43158 3.6875 7.24082 3.60848 7.10016 3.46783C6.95951 3.32718 6.88049 3.13641 6.88049 2.9375C6.88049 2.73859 6.95951 2.54782 7.10016 2.40717C7.24082 2.26652 7.43158 2.1875 7.63049 2.1875H10.6305Z"
                      fill="#BEBEBE"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div
            className="add-address"
            onClick={() => {
              setaddressCount(4);
            }}
            style={{
              display:
                addressCount === 4 ? "none" : addressCount < 4 ? "flex" : <></>,
            }}
          >
            <img src={add} alt="" />
            Add Address
          </div>
        </div>
      </div>
      <div
        className="address-4"
        style={{
          display: addressCount === 4 || addressCount > 4 ? "block" : "none",
        }}
      >
        <div className="form-right">
          <div className="form-item" style={{ marginTop: 20 }}>
            <label htmlFor="">Address 4</label>
            <div
              className="address-container"
              style={{ display: "flex", gap: 10 }}
            >
              <textarea
                name="address"
                id="address"
                value={addressUpdate?.[1] || ""}
                onChange={(e) => handleAddressChange(1, e.target.value)}
                cols="62"
                rows="10"
                placeholder="Enter Text"
              ></textarea>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  addressUpdate.splice(-1);
                  setaddressCount((count) => count - 1);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M15.1305 4.4375C15.3294 4.4375 15.5202 4.51652 15.6608 4.65717C15.8015 4.79782 15.8805 4.98859 15.8805 5.1875C15.8805 5.38641 15.8015 5.57718 15.6608 5.71783C15.5202 5.85848 15.3294 5.9375 15.1305 5.9375H14.3805L14.3782 5.99075L13.6785 15.794C13.6516 16.1724 13.4822 16.5266 13.2046 16.7852C12.9269 17.0438 12.5616 17.1875 12.1822 17.1875H6.07799C5.6986 17.1875 5.33329 17.0438 5.05566 16.7852C4.77802 16.5266 4.60868 16.1724 4.58174 15.794L3.88199 5.9915C3.88086 5.97352 3.88036 5.95551 3.88049 5.9375H3.13049C2.93158 5.9375 2.74082 5.85848 2.60016 5.71783C2.45951 5.57718 2.38049 5.38641 2.38049 5.1875C2.38049 4.98859 2.45951 4.79782 2.60016 4.65717C2.74082 4.51652 2.93158 4.4375 3.13049 4.4375H15.1305ZM12.8782 5.9375H5.38274L6.07874 15.6875H12.1822L12.8782 5.9375ZM10.6305 2.1875C10.8294 2.1875 11.0202 2.26652 11.1608 2.40717C11.3015 2.54782 11.3805 2.73859 11.3805 2.9375C11.3805 3.13641 11.3015 3.32718 11.1608 3.46783C11.0202 3.60848 10.8294 3.6875 10.6305 3.6875H7.63049C7.43158 3.6875 7.24082 3.60848 7.10016 3.46783C6.95951 3.32718 6.88049 3.13641 6.88049 2.9375C6.88049 2.73859 6.95951 2.54782 7.10016 2.40717C7.24082 2.26652 7.43158 2.1875 7.63049 2.1875H10.6305Z"
                      fill="#BEBEBE"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-buttons">
        <button className="btn-sec">Cancel</button>
        <button
          className="btn-pri"
          onClick={handleSave}
          disabled={isLoading ? true : false}
        >
          {isLoading ? <Spinner color="white" /> : `Save`}
        </button>
      </div>
    </div>
  );
};

export default Details;
