import React, { useEffect } from "react";
import "./DeleteConfirmationModal.css";

export function DeleteConfirmationModal({
  title,
  isOpen,
  setIsOpen,
  handleDelete,
}) {
  useEffect(() => {
    const affectedElements = document.querySelectorAll(".disable-on-modal-open");

    if (isOpen) {
      affectedElements.forEach((element) => {
        element.setAttribute("tabindex", "-1");
        element.blur();
      });
    } else {
      affectedElements.forEach((element) => {
        element.removeAttribute("tabindex");
      });
    }
  }, [isOpen]);

  return (
    isOpen && (
      <>
        <div className="overlay" onClick={() => setIsOpen(false)}></div>
        <div className="delete-modal">
          <h1>Delete {title}?</h1>
          <p>
            These details cannot be restored. Are you sure you want to delete
            it?
          </p>
          <button className="btn-pri" onClick={handleDelete}>
            Delete
          </button>
          <button onClick={() => setIsOpen(false)}>Cancel</button>
        </div>
      </>
    )
  );
}
