import LTALogo from "assets/images/lta_logo.svg";
import Header_web from "assets/images/happy_students.png";
import Header_mobile from "assets/images/lta_frame_5.png";

import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { getApplications, getEvents } from "utils/api";

import { AppContext } from "context/app_context";

import SkeletonEvents from "./components/home/SkeletonEvents";
import Events from "./components/home/Events";
import Overview from "./components/home/Overview";
import SkeletonOverview from "./components/home/SkeletonOverview";
import Application from "./components/profile/components/common/Application";
import MenuItem from "./components/menu_item/menu_item";

import "./dashboard.scss";
import "./components/profile/profile.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const [showOverlay, setOverlay] = useState(false);
  const { user } = useContext(AppContext);
  const [applications, setApplications] = useState([]);
  const tempApplication = useRef([]);
  const [imageSource, setImageSource] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true); // change to false to stop the loading look
  const [eventList, setEventList] = useState([]);
  const [mobileDash, setMobileDash] = useState(false)
  useEffect(() => {
    checkMediaQuery();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    downloadApplications();
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setImageSource(Header_web);
        setMobileDash(false);
      } else {
        setImageSource(Header_mobile);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const downloadApplications = async () => {
    try {
      const _applications = await getApplications();
      let reverseList = _applications.reverse();
      setApplications(reverseList);
      tempApplication.current = reverseList;
    } catch (error) {
      console.log(error);
    }
  };


  const checkMediaQuery = () => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventLists = await getEvents();
        setEventList(eventLists);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const searchUniversity = ({ target }) => {
    const { value } = target;
    if (value === "") {
      setApplications(tempApplication.current);
      return;
    }

    let _results = applications.filter((application) =>
      application?.universityName?.toUpperCase()?.includes(value?.toUpperCase())
    );
    setApplications(_results);
  };
  return (
    <section
      className="main_wrapper"
      onClick={() => {
        setOverlay(false);
      }}
    >
      <nav className="nav_container">

        <img src={LTALogo} alt="Logo main" className="lta_logo" />

        <div className="right_nav">
          <input
            type="text"
            placeholder="Search university"
            onChange={searchUniversity}
          />
          <div className="profile">
            <p className="username">
              {user.firstName || user.first_name}{" "}
              {user.lastName || user.last_name}
            </p>
            <div
              className="dp"
              onClick={(e) => {
                e.stopPropagation();
                setOverlay(!showOverlay);
              }}
            >
              <img src={user.dp || user.profile_image} alt="DP" />
            </div>
            {showOverlay ? (
              <div className="profile_overlay">
                <div className="name">{`${user.firstName || user.first_name} ${user.lastName || user.last_name
                  }`}</div>
                <div className="username">{user.username}</div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "darkred",
                    borderRadius: "6px",
                    padding: "4px 8px 8px 8px",
                  }}
                  onClick={() => {
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  Logout
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </nav>
      <div className="body_container">
        <div className="dash_sidebar">
          <MenuItem itemName="Dashboard" active={true} />
          {/* <MenuItem itemName="profile" active={false} /> */}
          <MenuItem itemName="profile" active={false} />
          {/* <MenuItem itemName="settings" active={false} /> */}
        </div>
        <div className="applications_section">
          <div className="application_header">
            <img src={imageSource} alt="Happy Students" />
            <div className="application_header_text">
              <div className="application_header_title">
                Hello {user.firstName}
              </div>
              <div className="application_header_subtitle">
                Here is your application status
              </div>
            </div>
            {loading ? (
              <SkeletonOverview></SkeletonOverview>
            ) : (
              <Overview applications={applications}></Overview>
            )}
            <div className="mobile_search">
              {isMobile ? (
                <input
                  type="text"
                  placeholder="Search"
                  onChange={searchUniversity}
                />
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="applications-table">
            <h1 className="application-heading desktop-only">Applications</h1>

            <div className="dashboard-nav mobile-only">
              <div className="dashboard-nav-inner">
                <span className={`nav-item ${!mobileDash ? "active-tab" : ""}`} onClick={() => { setMobileDash(false) }}>Applications</span>
                <span className={`nav-item ${mobileDash ? "active-tab" : ""}`} onClick={() => { setMobileDash(true) }}>Upcoming events</span>
              </div>
            </div>
            {!mobileDash ? (
              <>
                <div className="application-headers desktop-only">
                  <div className="text">
                    <h6>University & Course Name</h6>
                  </div>
                  <div className="more-text">
                    <h6>Application Status</h6>
                    <h6>Admission</h6>
                    <h6>Documents</h6>
                  </div>
                </div>
                <div className="applications-contain">
                  {applications.map((application) => (
                    <Application
                      logo={application.universityLogo}
                      university={application.universityName}
                      course={application.course}
                      status={application.applicationStage}
                      admission={application.admissionStatus}
                      documents={application.documents}
                      statusUrl={application.applicationDownload}
                      letterUrl={application.offerOrRejectionUrl}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="applications-contain mobile-only">
                  {eventList.map((event) => {
                    return (
                      <Events
                        title={event.title}
                        host={event.host}
                        date={event.date}
                        moth={event.moth}
                        eventType={event.eventType}
                        time={event.time}
                      ></Events>
                    )
                  })}
                </div>
              </>
            )}

          </div>
        </div>
        <div className="events_main_container desktop-only" >
          <div className="title_overview">Upcoming Events 2023</div>
          <div className="event_wrapper">
            {eventList.map((event) => {
              return (
                <div style={{ paddingRight: "8px" }}>
                  {loading ? (
                    <SkeletonEvents></SkeletonEvents>
                  ) : (
                    <Events
                      title={event.title}
                      host={event.host}
                      date={event.date}
                      moth={event.moth}
                      eventType={event.eventType}
                      time={event.time}
                    ></Events>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Dashboard;
