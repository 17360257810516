import { ltaClient } from "./axios.config";
import { formDataClient } from "./axios.config";

export const login = (cred) =>
  ltaClient({
    method: "POST",
    url: `/auth/login`,
    data: cred,
  }).then((res) => res.data);

export const signUp = (form) =>
  ltaClient({
    method: "POST",
    url: `/auth/createUser`,
    data: form,
  }).then((res) => res.data);

export const getApplications = ()=>ltaClient({
  method: "GET",
  url : `/application/getApplications`
}).then(res=>res.data);

export const validateToken = ()=>ltaClient({
  method: "GET",
  url : `/auth/validateToken`
}).then(res=>res.data);

//uploadfile
export const uploadFile = (form) =>
  formDataClient({
    method: "POST",
    url: `/document`,
    data: form,
  }).then((res) => res.data);

export const updateUser = (form) =>
  formDataClient({
    method: "PATCH",
    url: `/auth/updateUser`,
    data: form,
  }).then((res) => res.data);
export const downloadDocuments = ()=>ltaClient({
  method: "GET",
  url: `/document`
}).then(res=>res.data)
export const updateDoc = (docId,body)=>formDataClient({
  method: "PATCH",
  url: `/document/${docId}`,
  data: body
}).then(res=>res.data);
export const deleteDocs = (docId)=>ltaClient({
  method: "DELETE",
  url: `/document/${docId}`
}).then(res=>res.data);
export const updateAbout = (body)=>ltaClient({
  method: "PATCH",
  url: `/about`,
  data: body
}).then(res=>res.data)
export const getEvents = (body)=>ltaClient({
  method: "GET",
  url: '/event',
}).then(res=>res.data);
//updatefile