import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{display:"flex",minHeight: "600px",width: "100%",height:"100%",alignItems: "center",justifyContent:"center",flexDirection: "column"}}>
          <h2 style={{fontSize: "30px",fontWeight: "bold"}}>Oops, account blocked!</h2>
          <p style={{fontSize: "20px",fontWeight: "lighter",maxWidth:"700px",lineHeight: "2rem",textAlign:"center"}}>We apologize for the inconvenience. a suspicious activity detected in your account. Please conatct administrator</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;