import Select from "react-select";
import selectArrowImage from "assets/svg/select-arrow.png";

export default function SelectCustom({
  options = [],
  handler = () => {},
  value,
  error,
  update,
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Inter",
      border:
        update && (error.gender || error.degree)
          ? "1px solid red"
          : "1px solid #b5beca",
      border: update && error.degree ? "1px solid red" : "1px solid #b5beca",
      borderRadius: "10px",
      color: "#35465a",
      height: "40px",
      fontWeight: "400",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      paddingRight: "20px",
      alignItems: "center",
      justifyContent: "center",
      background: `url(${selectArrowImage}) no-repeat right center`,
      outline: state.isFocused && "2px solid #8764DE",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#8764DE" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#8764DE" : "#D5C8EF",
      },
    }),
    ValueContainer: (provided) => ({
      ...provided,
      alignItems: "start",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "2px",
      paddingLeft: "6px",
    }),
    singleValue: (provided) => ({
      ...provided,
      order: 1,
      color: "#35465a",
    }),
    Input: (provided) => ({
      ...provided,
      paddingLeft: "6px !important",
      order: 2,
      "& input": {
        paddingLeft: "6px",
      },
    }),
  };

  return (
    <Select
      id="degree"
      name="degree"
      options={options}
      value={value}
      onChange={handler}
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary50: "#ffffff",
          primary25: "hotpink",
          primary: "#8764DE19",
        },
      })}
    />
  );
}
