import React, { useState, useEffect } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import utils from "@hassanmojab/react-modern-calendar-datepicker";
import "./Date.scss";

const SelectDate = (props) => {
  const { date, setDate, error, setError, update, meta, type } = props;
  const [minimum, setMinimum] = useState({});

  const custom = (date) => {
    setDate(date);
    if (Object.keys(date).length === 3) {
      if (type === "dob") {
        meta.dob = `${date.year}-${date.month}-${date.day}`;
      }
      if (type === "expiryDate") {
        meta.expiryDate = `${date.year}-${date.month}-${date.day}`;
      }
      if (type === "startDate") {
        meta.startDate = `${date.year}-${date.month}-${date.day}`;
      }
      if (type === "expiryDate") {
        meta.expiryDate = `${date.year}-${date.month}-${date.day}`;
      }
    } else {
      if (!Object.values(date).includes(null)) {
        meta.startDate = `${date.from.year}-${date.from.month}-${date.from.day}`;
        meta.endDate = `${date.to.year}-${date.to.month}-${date.to.day}`;
      }
    }
    if (!date) {
      setError({ ...error, date: "Required" });
    } else {
      setError({ ...error, date: "" });
    }
  };

  useEffect(() => {
    if (type === "expiryDate") {
      const datee = new Date();
      let day = datee.getDate();
      let month = datee.getMonth() + 1;
      let year = datee.getFullYear();
      setMinimum({
        year: year,
        month: month,
        day: day,
      });
    }
  }, []);

  // render regular HTML input element
  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref} // necessary
      placeholder="dd-mm-yyyy"
      value={
        date === null || Object.values(date).includes(null)
          ? ""
          : Object.keys(date).length === 3
          ? `${date.day}/${date.month}/${date.year}`
          : `${date.from.day}/${date.from.month}/${date.from.year} - ${date.to.day}/${date.to.month}/${date.to.year}`
      }
      style={{
        padding: "0px 16px",
        fontSize: "14px",
        borderRadius: "8px",
        fontFamily: "Inter",
        color: "#35465a",
        height: "40px",
        width: "100%",
        border: update && error.date ? "1px solid red" : "1px solid #b5beca",
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'%3E%3Cpath d='M18.9075 4.08008H16.9075V3.08008C16.9075 2.81486 16.8021 2.56051 16.6146 2.37297C16.427 2.18543 16.1727 2.08008 15.9075 2.08008C15.6423 2.08008 15.3879 2.18543 15.2004 2.37297C15.0128 2.56051 14.9075 2.81486 14.9075 3.08008V4.08008H8.90747V3.08008C8.90747 2.81486 8.80211 2.56051 8.61458 2.37297C8.42704 2.18543 8.17269 2.08008 7.90747 2.08008C7.64225 2.08008 7.3879 2.18543 7.20036 2.37297C7.01283 2.56051 6.90747 2.81486 6.90747 3.08008V4.08008H4.90747C4.11182 4.08008 3.34876 4.39615 2.78615 4.95876C2.22354 5.52137 1.90747 6.28443 1.90747 7.08008V19.0801C1.90747 19.8757 2.22354 20.6388 2.78615 21.2014C3.34876 21.764 4.11182 22.0801 4.90747 22.0801H18.9075C19.7031 22.0801 20.4662 21.764 21.0288 21.2014C21.5914 20.6388 21.9075 19.8757 21.9075 19.0801V7.08008C21.9075 6.28443 21.5914 5.52137 21.0288 4.95876C20.4662 4.39615 19.7031 4.08008 18.9075 4.08008ZM19.9075 19.0801C19.9075 19.3453 19.8021 19.5996 19.6146 19.7872C19.427 19.9747 19.1727 20.0801 18.9075 20.0801H4.90747C4.64225 20.0801 4.3879 19.9747 4.20036 19.7872C4.01283 19.5996 3.90747 19.3453 3.90747 19.0801V12.0801H19.9075V19.0801ZM19.9075 10.0801H3.90747V7.08008C3.90747 6.81486 4.01283 6.56051 4.20036 6.37297C4.3879 6.18544 4.64225 6.08008 4.90747 6.08008H6.90747V7.08008C6.90747 7.34529 7.01283 7.59965 7.20036 7.78718C7.3879 7.97472 7.64225 8.08008 7.90747 8.08008C8.17269 8.08008 8.42704 7.97472 8.61458 7.78718C8.80211 7.59965 8.90747 7.34529 8.90747 7.08008V6.08008H14.9075V7.08008C14.9075 7.34529 15.0128 7.59965 15.2004 7.78718C15.3879 7.97472 15.6423 8.08008 15.9075 8.08008C16.1727 8.08008 16.427 7.97472 16.6146 7.78718C16.8021 7.59965 16.9075 7.34529 16.9075 7.08008V6.08008H18.9075C19.1727 6.08008 19.427 6.18544 19.6146 6.37297C19.8021 6.56051 19.9075 6.81486 19.9075 7.08008V10.0801Z' fill='%23888C92'/%3E%3C/svg%3E")`,
        backgroundPosition: "right 10px center", // position the icon
        backgroundRepeat: "no-repeat",
      }}
      className="my-custom-input-class"
    />
  );

  return (
    <DatePicker
      value={date}
      onChange={custom}
      calendarClassName="custom-calendar"
      renderInput={renderCustomInput}
      calendarRangeStartClassName="custom-start"
      inputClassName="custom-placeholder"
      calendarRangeEndClassName="custom-end"
      minimumDate={type === "expiryDate" ? minimum : null}
      colorPrimary="#8764DE"
      colorPrimaryLight="#F2F7FE"
    />
  );
};

export default SelectDate;
