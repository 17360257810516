import axios from "axios";
import { getToken } from "./local";

const baseUrl = `https://api.letterstoabroad.com`;
//const baseUrl = `http://localhost:8001`;

export const ltaClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*', // Replace with your frontend's origin
    'Content-Type': 'application/json',
  },
});

export const formDataClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*', // Replace with your frontend's origin
    'Content-Type': 'multipart/form-data',
  },
});

ltaClient.interceptors.request.use((config)=>{
    let token = getToken();
    if(token){
        config.headers["auth-token"] = `Bearer ${token}`
    }
    return config;
})

formDataClient.interceptors.request.use((config)=>{
  let token = getToken();
  if(token){
      config.headers["auth-token"] = `Bearer ${token}`
  }
  return config;
})
formDataClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.clear();
      window.location.href = "https://app.letterstoabroad.com/"
    }
    return Promise.reject(error);
  }
);
ltaClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.clear();
      window.location.href = "https://app.letterstoabroad.com/"
    }
    return Promise.reject(error);
  }
);

