import React from "react";
import "./DropDown.scss";

export default function DropDown({ setEditing, handleDeleteDocClick }) {
  return (
    <div className="edit-modal">
      <div className="edit-modal-content">
        <p
          onClick={(e) => {
            e.stopPropagation();
            setEditing(true);
          }}
        >
          Edit
        </p>
        <p
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteDocClick();
          }}
        >
          Delete
        </p>
        <p>Close</p>
      </div>
    </div>
  );
}
