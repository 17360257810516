import { useRef, useState, useEffect } from "react";
import "../../profile.scss";

import docIcon from "assets/svg/ph_file.svg";
import crossIcon from "assets/svg/cross.svg";
import selectArrowImage from "assets/svg/select-arrow.png";

import { Spinner } from "@chakra-ui/react";
import Select from "react-select";
import UploadContainer from "../common/UploadContainer";
import SelectCustom from "../common/SelectCustom/SelectCustom";
import { HorizontalLine } from "../common/HorizontalLine/HorizontalLine";

const EducationForm = (props) => {
  const [update, setUpdate] = useState(false);
  const { key, data, onSave, isEdit, onCancel, loading } = props;
  const { college, degree, fileType } = data;
  const [disable, setDisable] = useState(false);
  const [doc, setDoc] = useState("");
  const [meta, setMeta] = useState({});
  const [error, setError] = useState({
    degree: "Required",
    certificate: "",
    college: "Required",
    doc: "Required",
  });
  const inputDoc = useRef(null);

  useEffect(() => {
    setMeta({ degree, college, fileType });
  }, [college, degree, fileType]);

  const handleDegreeFile = (file) => {
    setDoc(file);
  };

  const handleSave = () => {
    console.log(meta);
    if (Object.values(error).every((value) => value === "")) {
      if (meta.fileType === undefined) {
        setError({ ...error, certificate: "Required" });
      } else {
        setDisable(true);
        const updateForm = new FormData();
        updateForm.append("meta", JSON.stringify(meta));
        updateForm.append("type", "Education");

        if (doc) {
          updateForm.append("doc", doc);
        }

        onSave(updateForm);
      }
    } else {
      setUpdate(true);
    }
  };

  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    setMeta({ ...meta, [name]: value });
  };

  const handleDegree = (e) => {
    handleSelectChange(e);
    if (!e.value) {
      setError({ ...error, degree: "Required" });
    } else {
      setError({ ...error, degree: "" });
    }
  };

  const handleCertificatae = (e) => {
    handleDegreeChange(e);
    if (!e.value) {
      setError({ ...error, certificate: "Required" });
    } else {
      setError({ ...error, certificate: "" });
    }
  };

  const handleCollege = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, college: "Required" });
    } else {
      setError({ ...error, college: "" });
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setMeta({ ...meta, degree: selectedOption.value });
    }
  };
  const handleDegreeChange = (selectedOption) => {
    if (selectedOption) {
      setMeta({ ...meta, fileType: selectedOption.value });
    }
  };
  const handleClearDocument = () => {
    inputDoc.current.value = null;
    setDoc("");
  };

  const degreeOptions = [
    { value: "10th", label: "10th Grade" },
    { value: "12th", label: "12th Grade" },
    { value: "UG", label: "Under Graduate" },
    { value: "PG", label: "Post Graduate" },
  ];

  const certificateOptions = [
    { value: "Final Certificate", label: "Final Certificate" },
    { value: "Transcript", label: "Transcript" },
    {
      value: "Consolidated Grade Card",
      label: "Consolidated Grade Card",
    },
    {
      value: "Individual Semester Grades",
      label: "Individual Semester Grades",
    },
  ];

  return (
    <div className="education-main-container" key={key}>
      <form className="education-container">
        <div className="degree-left">
          <div className="form-item desp">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label htmlFor="">Degree</label>

              <SelectCustom
                options={degreeOptions}
                handler={handleDegree}
                value={
                  meta.degree
                    ? { value: meta.degree, label: meta.degree }
                    : { value: "", label: "Choose Degree" }
                }
                error={error}
                update={update}
              />

              {update && error.degree ? (
                <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                  *{error.degree}
                </h6>
              ) : (
                <></>
              )}
            </div>

            {meta.degree === "UG" || meta.degree === "PG" ? (
              <div className="form-item">
                <label htmlFor="">Type of Certificate</label>
                <SelectCustom
                  options={certificateOptions}
                  handler={handleCertificatae}
                  value={
                    meta.fileType
                      ? { value: meta.fileType, label: meta.fileType }
                      : { value: "", label: "Choose Certificate Type" }
                  }
                  error={error}
                  update={update}
                />
                {update && error.certificate ? (
                  <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                    *{error.certificate}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div className="form-item">
                <label htmlFor="">Type of Certificate</label>
                <SelectCustom
                  options={[{ value: "Grade Card", label: "Grade Card" }]}
                  handler={handleCertificatae}
                  value={
                    meta.fileType
                      ? { value: meta.fileType, label: meta.fileType }
                      : { value: "", label: "Choose Certificate Type" }
                  }
                  error={error}
                  update={update}
                />
                {update && error.certificate ? (
                  <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                    *{error.certificate}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className="form-item">
            <label htmlFor="">School/ College name</label>
            <input
              type="text"
              name="college"
              value={meta.college}
              placeholder="Enter School / College name"
              style={update && error.college ? { border: "1px solid red" } : {}}
              onChange={handleCollege}
            />
            {update && error.college ? (
              <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                *{error.college}
              </h6>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="right" style={{ gap: 3 }}>
          <UploadContainer
            doc={doc}
            setDoc={setDoc}
            error={error}
            setError={setError}
            update={update}
          />
        </div>
      </form>

      <div className="profile-buttons" style={{ marginTop: "20px" }}>
        <button
          className="btn-sec"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
        <button
          className="btn-pri"
          onClick={handleSave}
          disabled={loading ? true : false}
        >
          {loading ? <Spinner color="white" /> : `Save`}
        </button>
      </div>
      <HorizontalLine />
    </div>
  );
};
export default EducationForm;
