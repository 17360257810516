import "../profile.scss";
import add from "assets/svg/Frame 2.svg";

import { useState } from "react";

import { downloadDocuments, uploadFile } from "utils/api";

import { useQuery } from "react-query";
import { queryClient } from "App";

import EducationCard from "./EducationSection/EducationCard";
import EducationForm from "./EducationSection/EducationForm";

import { HorizontalLine } from "./common/HorizontalLine/HorizontalLine";

import Language from "./language/LanguageCard";
import LanguageForm from "./language/LanguageForm";
import { toast } from "react-toastify";

const Education = () => {
  const [showForm, setShowForm] = useState(false);
  const [showFormLanguage, setShowFormLanguage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [degree, setDegree] = useState("");
  const [college, setCollege] = useState("");

  const downloadDegreeList = async () => {
    let _degrees = await downloadDocuments();
    return _degrees;
  };

  const {
    data: data_degree,
    error: error_degree,
    isLoading: isLoading_degree,
  } = useQuery("data_degree", downloadDegreeList);

  const degree_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Education");

  const language_files = isLoading_degree
    ? []
    : data_degree.filter((_degree) => _degree.doctype === "Language");

  const createNewDoc = async (form) => {
    setLoading(true);

    try {
      let newDoc = await uploadFile(form);
      queryClient.invalidateQueries("data_degree");

      toast.success("Document uploaded successfully");

      setShowForm(false);
      setShowFormLanguage(false);
      setLoading(false);
    } catch (error) {
      setShowForm(false);

      toast.error("Something went wrong!");

      setShowFormLanguage(false);
      setLoading(false);
    }
  };

  return (
    <div className="documents-main-container">
      <div className="lang-main-container">
        <div className="lang-container" id="auto">
          <p className="academics-heading">
            Academics{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>

          {isLoading_degree
            ? `Loading...`
            : degree_files.map((_degree, i) => {
              return <EducationCard data={_degree} key={i} />;
            })}

          <HorizontalLine
            showForm={showForm}
            totalCards={degree_files.length}
          />

          {message && <div className="message">{message}</div>}

          {showForm && (
            <EducationForm
              key={0}
              data={{}}
              onSave={createNewDoc}
              loading={isLoading}
              isEdit={false}
              onCancel={() => {
                setShowForm(false);
              }}
            />
          )}

          {!showForm && (
            <div
              className="add_wrapper"
              onClick={() => {
                setShowForm(true);
              }}
            >
              <img src={add} alt="add button" />
              Add Education
            </div>
          )}
        </div>
      </div>

      <div className="lang-main-container">
        <div className="lang-container">
          <p className="academics-heading">
            Language{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              (Max file size 2MB)
            </span>
          </p>

          {isLoading_degree
            ? `Loading...`
            : language_files.map((_degree, i) => {
              return <Language data={_degree} key={i} />;
            })}
        </div>

        <HorizontalLine
          showForm={showForm}
          totalCards={language_files.length}
        />

        {showFormLanguage && (
          <LanguageForm
            key={0}
            data={{}}
            onSave={createNewDoc}
            isEdit={false}
            loading={isLoading}
            onCancel={() => {
              setShowFormLanguage(false);
            }}
          />
        )}

        {!showFormLanguage && (<div
          className="add_wrapper"
          onClick={() => {
            setShowFormLanguage(true);
          }}
        >
          <img src={add} alt="add button" />
          Add Language
        </div>
        )}
      </div>
    </div>
  );
};

export default Education;
