import "./Application.scss";
import threeDots from "assets/svg/Kebab button.svg";
import ApplicationMobileModal from "./ApplicationMobileModal";

import { useState } from "react";

export const ApplicationStatus = ({ status, url, verbose = false }) => {
  return (
    <div className="tag-container" style={{cursor: "pointer"}}>
      <p className="mobile-only">{verbose ? "APPLICATION" : "APPLN"}</p>
      {/* <div className={`tag application-status ${!status && "pending"}`}> */}
      <div className={`tag application-status ${status === "Submitted" ? "" : "pending"}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          className="desktop-only"
          style={{cursor: "pointer"}}
          onClick={() => {  if (url) {
            window.open(url);
          }}}
        >
          <path
            d="M12.4688 8.4585V11.521C12.4688 11.8111 12.3535 12.0893 12.1484 12.2944C11.9433 12.4995 11.6651 12.6147 11.375 12.6147H2.625C2.33492 12.6147 2.05672 12.4995 1.8516 12.2944C1.64648 12.0893 1.53125 11.8111 1.53125 11.521V8.4585C1.53125 8.28445 1.60039 8.11753 1.72346 7.99446C1.84653 7.87139 2.01345 7.80225 2.1875 7.80225C2.36155 7.80225 2.52847 7.87139 2.65154 7.99446C2.77461 8.11753 2.84375 8.28445 2.84375 8.4585V11.3022H11.1563V8.4585C11.1563 8.28445 11.2254 8.11753 11.3485 7.99446C11.4715 7.87139 11.6385 7.80225 11.8125 7.80225C11.9865 7.80225 12.1535 7.87139 12.2765 7.99446C12.3996 8.11753 12.4688 8.28445 12.4688 8.4585ZM6.5357 8.92279C6.59667 8.98397 6.66912 9.03252 6.74889 9.06564C6.82865 9.09876 6.91418 9.11581 7.00055 9.11581C7.08692 9.11581 7.17244 9.09876 7.25221 9.06564C7.33198 9.03252 7.40442 8.98397 7.46539 8.92279L9.65289 6.73529C9.77618 6.61201 9.84544 6.4448 9.84544 6.27045C9.84544 6.0961 9.77618 5.92889 9.65289 5.80561C9.52961 5.68232 9.3624 5.61306 9.18805 5.61306C9.0137 5.61306 8.84649 5.68232 8.7232 5.80561L7.65625 6.87256V2.3335C7.65625 2.15945 7.58711 1.99253 7.46404 1.86946C7.34097 1.74639 7.17405 1.67725 7 1.67725C6.82595 1.67725 6.65903 1.74639 6.53596 1.86946C6.41289 1.99253 6.34375 2.15945 6.34375 2.3335V6.87256L5.2768 5.8067C5.15351 5.68342 4.9863 5.61415 4.81195 5.61415C4.6376 5.61415 4.47039 5.68342 4.34711 5.8067C4.22383 5.92998 4.15456 6.09719 4.15456 6.27154C4.15456 6.44589 4.22383 6.6131 4.34711 6.73639L6.5357 8.92279Z"
            fill={status === "Submitted" ? "#BD8D19" : "#838A94"}
          />
        </svg>
        {status}
      </div>
    </div>
  );
};

export const AdmissionStatus = ({ status, verbose = false }) => {
  return (
    <div className="tag-container">
      <p className="mobile-only">{verbose ? "ADMISSION" : "ADMSN"}</p>
      <div className={`tag application-admission ${status === "Rejected" ? "rejected" : status === "Confirmed" ? "confirmed" : "pending"}`}>
        {status }
      </div>
    </div>
  );
};

const Application = ({
  logo,
  university,
  course,
  status,
  admission,
  statusUrl,
  letterUrl
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="application-container">
      <div className="image-text">
        <div className="application-image">
          <img
            src={logo ? logo : `/svg/university.svg`}
            width="32"
            height="32"
            alt="university logo"
          ></img>
        </div>

        <div className="application-details">
          <h3>{university}</h3>
          {course === "undefined" ? "" : <p className="desktop-only">{course}</p>}

          {course === "undefined" ? "" : <p className="mobile-only">{course}</p>}
        </div>

        <img
          src={threeDots}
          alt=""
          className="three-dots mobile-only"
          onClick={() => setIsOpen(true)}
        />
      </div>

      <div className="styles">
        <ApplicationStatus status={status} url={statusUrl} />
        <span className="mobile-only">•</span>
        <AdmissionStatus status={admission}/>

        {admission === "Confirmed" ? (
          <div className="tag-container" style={{cursor: "pointer"}}>
            <div className="tag application-documents desktop-only">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{cursor: "pointer"}}
                onClick={() => window.open(letterUrl)}
              >
                <path
                  d="M12.4688 8.4585V11.521C12.4688 11.8111 12.3535 12.0893 12.1484 12.2944C11.9433 12.4995 11.6651 12.6147 11.375 12.6147H2.625C2.33492 12.6147 2.05672 12.4995 1.8516 12.2944C1.64648 12.0893 1.53125 11.8111 1.53125 11.521V8.4585C1.53125 8.28445 1.60039 8.11753 1.72346 7.99446C1.84653 7.87139 2.01345 7.80225 2.1875 7.80225C2.36155 7.80225 2.52847 7.87139 2.65154 7.99446C2.77461 8.11753 2.84375 8.28445 2.84375 8.4585V11.3022H11.1563V8.4585C11.1563 8.28445 11.2254 8.11753 11.3485 7.99446C11.4715 7.87139 11.6385 7.80225 11.8125 7.80225C11.9865 7.80225 12.1535 7.87139 12.2765 7.99446C12.3996 8.11753 12.4688 8.28445 12.4688 8.4585ZM6.5357 8.92279C6.59667 8.98397 6.66912 9.03252 6.74889 9.06564C6.82865 9.09876 6.91418 9.11581 7.00055 9.11581C7.08692 9.11581 7.17244 9.09876 7.25221 9.06564C7.33198 9.03252 7.40442 8.98397 7.46539 8.92279L9.65289 6.73529C9.77618 6.61201 9.84544 6.4448 9.84544 6.27045C9.84544 6.0961 9.77618 5.92889 9.65289 5.80561C9.52961 5.68232 9.3624 5.61306 9.18805 5.61306C9.0137 5.61306 8.84649 5.68232 8.7232 5.80561L7.65625 6.87256V2.3335C7.65625 2.15945 7.58711 1.99253 7.46404 1.86946C7.34097 1.74639 7.17405 1.67725 7 1.67725C6.82595 1.67725 6.65903 1.74639 6.53596 1.86946C6.41289 1.99253 6.34375 2.15945 6.34375 2.3335V6.87256L5.2768 5.8067C5.15351 5.68342 4.9863 5.61415 4.81195 5.61415C4.6376 5.61415 4.47039 5.68342 4.34711 5.8067C4.22383 5.92998 4.15456 6.09719 4.15456 6.27154C4.15456 6.44589 4.22383 6.6131 4.34711 6.73639L6.5357 8.92279Z"
                  fill="#23AA7A"
                />
              </svg>
              Offer Letter
            </div>
          </div>
        ) : admission === "Rejected" ? (
          <div className="tag-container" style={{cursor: "pointer"}}>
            <div className="tag application-documents rejected desktop-only">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{cursor: "pointer"}}
                onClick={() => window.open(letterUrl)}
              >
                <path
                  d="M12.4688 8.4585V11.521C12.4688 11.8111 12.3535 12.0893 12.1484 12.2944C11.9433 12.4995 11.6651 12.6147 11.375 12.6147H2.625C2.33492 12.6147 2.05672 12.4995 1.8516 12.2944C1.64648 12.0893 1.53125 11.8111 1.53125 11.521V8.4585C1.53125 8.28445 1.60039 8.11753 1.72346 7.99446C1.84653 7.87139 2.01345 7.80225 2.1875 7.80225C2.36155 7.80225 2.52847 7.87139 2.65154 7.99446C2.77461 8.11753 2.84375 8.28445 2.84375 8.4585V11.3022H11.1563V8.4585C11.1563 8.28445 11.2254 8.11753 11.3485 7.99446C11.4715 7.87139 11.6385 7.80225 11.8125 7.80225C11.9865 7.80225 12.1535 7.87139 12.2765 7.99446C12.3996 8.11753 12.4688 8.28445 12.4688 8.4585ZM6.5357 8.92279C6.59667 8.98397 6.66912 9.03252 6.74889 9.06564C6.82865 9.09876 6.91418 9.11581 7.00055 9.11581C7.08692 9.11581 7.17244 9.09876 7.25221 9.06564C7.33198 9.03252 7.40442 8.98397 7.46539 8.92279L9.65289 6.73529C9.77618 6.61201 9.84544 6.4448 9.84544 6.27045C9.84544 6.0961 9.77618 5.92889 9.65289 5.80561C9.52961 5.68232 9.3624 5.61306 9.18805 5.61306C9.0137 5.61306 8.84649 5.68232 8.7232 5.80561L7.65625 6.87256V2.3335C7.65625 2.15945 7.58711 1.99253 7.46404 1.86946C7.34097 1.74639 7.17405 1.67725 7 1.67725C6.82595 1.67725 6.65903 1.74639 6.53596 1.86946C6.41289 1.99253 6.34375 2.15945 6.34375 2.3335V6.87256L5.2768 5.8067C5.15351 5.68342 4.9863 5.61415 4.81195 5.61415C4.6376 5.61415 4.47039 5.68342 4.34711 5.8067C4.22383 5.92998 4.15456 6.09719 4.15456 6.27154C4.15456 6.44589 4.22383 6.6131 4.34711 6.73639L6.5357 8.92279Z"
                  fill="#F85656"
                />
              </svg>
              Rejection Letter
            </div>
          </div>
        ) : (
          <div className="tag-container">
          <div className="tag application-status pending desktop-only">
            NA
          </div>
        </div>
        )}
      </div>

      {isOpen && (
        <ApplicationMobileModal
          logo={logo}
          setIsOpen={setIsOpen}
          university={university}
          course={course}
          status={status}
          admission={admission}
          statusUrl={statusUrl}
          letterUrl={letterUrl}
        />
      )}
    </div>
  );
};

export default Application;
