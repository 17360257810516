import { useRef, useState, useEffect } from "react";
import "../../profile.scss";

import { Spinner } from "@chakra-ui/react";

import docIcon from "assets/svg/ph_file.svg";
import crossIcon from "assets/svg/cross.svg";
import selectArrowImage from "assets/svg/select-arrow.png";

import Select from "react-select";
import UploadContainer from "../common/UploadContainer";
import { HorizontalLine } from "../common/HorizontalLine/HorizontalLine";

const LanguageForm = (props) => {
  const [update, setUpdate] = useState(false);
  const { key, data, onSave, isEdit, onCancel, loading } = props;
  const { language, certificate, score } = data;
  const [doc, setDoc] = useState("");
  const [meta, setMeta] = useState({});
  const [error, setError] = useState({
    language: "Required",
    certificate: "Required",
    score: "Required",
    doc: "Required",
  });
  const inputDoc = useRef(null);
  const [certificateOptions, setCertificateOptions] = useState([]);

  useEffect(() => {
    setMeta({ language, certificate, score });
  }, [language, certificate, score, isEdit]);

  const handleDegreeFile = (file) => {
    setDoc(file);
  };
  const handleSave = () => {
    if (Object.values(error).every((value) => value === "")) {
      const updateForm = new FormData();
      updateForm.append("meta", JSON.stringify(meta));
      updateForm.append("type", "Language");
      if (doc) {
        updateForm.append("doc", doc);
      }

      onSave(updateForm);
    } else {
      setUpdate(true);
    }
  };

  const handleLanguage = (e) => {
    handleSelectChange(e);
    if (!e.value) {
      setError({ ...error, language: "Required" });
    } else {
      setError({ ...error, language: "" });
    }
  };

  const handleCertificate = (e) => {
    handleCertificateChange(e);
    if (!e.value) {
      setError({ ...error, certificate: "Required" });
    } else {
      setError({ ...error, certificate: "" });
    }
  };

  const handleScore = (e) => {
    handleChangeInput(e);
    if (!e.target.value) {
      setError({ ...error, score: "Required" });
    } else {
      setError({ ...error, score: "" });
    }
  };

  const handleUploadClick = () => {
    inputDoc.current.click();
  };

  const handleChangeInput = ({ target }) => {
    setError({ language: false, certificate: false, score: false, doc: false });
    const { name, value } = target;
    setMeta({ ...meta, [name]: value });
  };

  const handleCertificateChange = (selectedOption) => {
    if (selectedOption) {
      setMeta({
        ...meta,
        certificate: selectedOption.value,
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setMeta({ ...meta, language: selectedOption.value });

      if (selectedOption.value === "English") {
        setCertificateOptions([{ value: "IELTS", label: "IELTS" }]);
      } else if (selectedOption.value === "German") {
        setCertificateOptions([
          { value: "A1", label: "A1" },
          { value: "A2", label: "A2" },
          { value: "B1", label: "B1" },
          { value: "B2", label: "B2" },
          { value: "C1", label: "C1" },
          { value: "C2", label: "C2" },
        ]);
      } else {
        setCertificateOptions([]);
      }
    }
  };

  const handleClearDocument = () => {
    inputDoc.current.value = null;
    setDoc("");
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // padding: "8px 16px",
      fontSize: "14px",
      fontFamily: "Inter",
      // border: update && error.language ? "1px solid red" : "1px solid #b5beca",
      // backgroundColor: state.isSelected ? "#8764DE" : "white",
      borderRadius: "10px",
      color: "#35465a",
      height: "40px",
      fontWeight: "400",
      appearance: "none",
      WebkitAppearance: "none",
      MozAppearance: "none",
      paddingRight: "20px",
      alignItems: "center",
      justifyContent: "center",
      background: `url(${selectArrowImage}) no-repeat right center`,
      // borderColor: state.isFocused ? "#000" : provided.borderColor,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#8764DE" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: state.isSelected ? "#8764DE" : "#D5C8EF",
      },
    }),
    ValueContainer: (provided) => ({
      ...provided,
      alignItems: "start",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "2px",
      paddingLeft: "6px",
    }),
    singleValue: (provided) => ({
      ...provided,
      order: 1,
      color: "#35465a",
    }),
    Input: (provided) => ({
      ...provided,
      paddingLeft: "6px !important",
      order: 2,
      "& input": {
        paddingLeft: "6px",
      },
    }),
  };

  return (
    <div className="education-main-container" key={key}>
      <form className="education-container">
        <div className="degree-left">
          <div className="form-item">
            <label htmlFor="">Language</label>
            <Select
              id="language"
              name="language"
              options={[
                { value: "English", label: "English" },
                { value: "German", label: "German" },
              ]}
              value={
                meta.language
                  ? { value: meta.language, label: meta.language }
                  : { value: "", label: "Choose Language" }
              }
              onChange={handleLanguage}
              styles={{
                ...customStyles,
                control: (provided, state) => ({
                  ...provided,
                  // padding: "8px 16px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  border:
                    update && error.language
                      ? "1px solid red"
                      : "1px solid #b5beca",
                  // backgroundColor: state.isSelected ? "#8764DE" : "white",
                  borderRadius: "10px",
                  color: "#35465a",
                  height: "40px",
                  fontWeight: "400",
                  appearance: "none",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  paddingRight: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  background: `url(${selectArrowImage}) no-repeat right center`,
                  outline: state.isFocused && "2px solid #8764DE",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary50: "#ffffff",
                  primary25: "hotpink",
                  primary: "#8764DE19",
                },
              })}
            />
            {update && error.language ? (
              <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                *{error.language}
              </h6>
            ) : (
              <></>
            )}
          </div>

          <div className="cert-container">
            <div className="form-item">
              <label htmlFor="">Certificate</label>
              <Select
                id="certificate"
                name="certificate"
                options={certificateOptions}
                value={
                  meta.certificate
                    ? { value: meta.certificate, label: meta.certificate }
                    : { value: "", label: "Choose Certificate" }
                }
                onChange={handleCertificate}
                styles={{
                  ...customStyles,
                  control: (provided, state) => ({
                    ...provided,
                    // padding: "8px 16px",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    border:
                      update && error.certificate
                        ? "1px solid red"
                        : "1px solid #b5beca",
                    // backgroundColor: state.isSelected ? "#8764DE" : "white",
                    borderRadius: "10px",
                    color: "#35465a",
                    height: "40px",
                    fontWeight: "400",
                    appearance: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    paddingRight: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: `url(${selectArrowImage}) no-repeat right center`,
                    outline: state.isFocused && "2px solid #8764DE",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary50: "#ffffff",
                    primary25: "hotpink",
                    primary: "#8764DE19",
                  },
                })}
              />
              {update && error.certificate ? (
                <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                  *{error.certificate}
                </h6>
              ) : (
                <></>
              )}
              {/* <input
                type="text"
                name="certificate"
                id="certificate"
                onChange={handleChangeInput}
                value={meta.certificate}
                placeholder="Enter Certificate name"
              /> */}
            </div>
            <div className="form-item">
              <label htmlFor="">Overall Score</label>
              <input
                type="text"
                name="score"
                id="score"
                onChange={handleScore}
                value={meta.score}
                placeholder="Enter Score"
                style={update && error.score ? { border: "1px solid red" } : {}}
              />
              {update && error.score ? (
                <h6 style={{ color: "red", height: 20, fontSize: 14 }}>
                  *{error.score}
                </h6>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="right">
          <UploadContainer
            doc={doc}
            setDoc={setDoc}
            error={error}
            setError={setError}
            update={update}
          />
        </div>
      </form>
      <div className="profile-buttons" style={{ marginTop: "20px" }}>
        <button
          className="btn-sec"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </button>
        <button
          className="btn-pri"
          onClick={handleSave}
          disabled={loading ? true : false}
        >
          {loading ? <Spinner color="white" /> : `Save`}
        </button>
      </div>
      <HorizontalLine />
    </div>
  );
};
export default LanguageForm;
