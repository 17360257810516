import { useEffect } from "react";
import "./menu_item.scss";
import { useNavigate } from "react-router-dom";

const MenuItem = ({ itemName, active, path }) => {
  const navigate = useNavigate();

  return (
    <section
      className="menu_item_main_wrapper"
      onClick={() => {
        itemName === "settings" ? <></> : navigate(`/${itemName}`);
      }}
    >
      <div className={`menu_item ${active ? `active` : ``}`}>
        <img
          src={active ? `/svg/${itemName}_active.svg` : `/svg/${itemName}_inactive.svg`}
        />
      </div>
    </section>
  );
};

export default MenuItem;
