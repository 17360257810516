import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/app_context";
import { login } from "../../utils/api";
import { saveUser, setToken } from "../../utils/local";
// import { httpGoConsole } from './netrowk';
import "./signin.css";

const SignIn = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AppContext);
  const [form, setForm] = useState({});
  const [errorAuthResponse,setErrorAuthResponse] = useState({});

  const handleClickSubmit = async ({ target }) => {
    let authResponse = await login(form);
    setErrorAuthResponse(authResponse);
    const { userId, username } = authResponse;
    const { phone } = authResponse;
    if (userId && username === form.username) {
      saveUser(JSON.stringify(authResponse));
      setUser(authResponse);
      setToken(authResponse.accessToken);
      navigate("/dashboard");
    }
  };

  const handleInputChange = ({ target }) => {
    setErrorAuthResponse({});
    const { value, name } = target;
    setForm({
      ...form,
      [name]: value.trim(),
    });
  };
  
  return (
    <div className="login_main_wrapper">
      <div className="login_container">
        <div className="title">Login</div>
        <div className="input_container">
          <div>Email</div>
          <input type="text" placeholder="Email" name="username" className={errorAuthResponse.isUsernameError?'error':''} value={form["username"]} onChange={handleInputChange}/>
        </div>
        <div className="input_container">
          <div>Password</div>
          <input  type="password" className={errorAuthResponse.isPasswordError?'error':''} placeholder="Password" name="password" value={form["password"]} onChange={handleInputChange}/>
        </div>
        <div className="forgot_password_container">
        <a href="/forgot">Forgot password?</a>
        </div>
        <div className="button_container">
          <button onClick={handleClickSubmit}>Login</button>
        </div>
        <div className="existing_user_container">
          I’m a new user. <a href="/signup">Create Account</a>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default SignIn;
