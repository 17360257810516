import React from "react";
import "./Application.scss";
import "./ApplicationMobileModal.scss";

import back from "assets/svg/back.svg";
import { AdmissionStatus, ApplicationStatus } from "./Application";

const DownloadSvg = ({ fill = "#838A94" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 14 15"
      className="desktop-only"
    >
      <path
        d="M12.4688 8.4585V11.521C12.4688 11.8111 12.3535 12.0893 12.1484 12.2944C11.9433 12.4995 11.6651 12.6147 11.375 12.6147H2.625C2.33492 12.6147 2.05672 12.4995 1.8516 12.2944C1.64648 12.0893 1.53125 11.8111 1.53125 11.521V8.4585C1.53125 8.28445 1.60039 8.11753 1.72346 7.99446C1.84653 7.87139 2.01345 7.80225 2.1875 7.80225C2.36155 7.80225 2.52847 7.87139 2.65154 7.99446C2.77461 8.11753 2.84375 8.28445 2.84375 8.4585V11.3022H11.1563V8.4585C11.1563 8.28445 11.2254 8.11753 11.3485 7.99446C11.4715 7.87139 11.6385 7.80225 11.8125 7.80225C11.9865 7.80225 12.1535 7.87139 12.2765 7.99446C12.3996 8.11753 12.4688 8.28445 12.4688 8.4585ZM6.5357 8.92279C6.59667 8.98397 6.66912 9.03252 6.74889 9.06564C6.82865 9.09876 6.91418 9.11581 7.00055 9.11581C7.08692 9.11581 7.17244 9.09876 7.25221 9.06564C7.33198 9.03252 7.40442 8.98397 7.46539 8.92279L9.65289 6.73529C9.77618 6.61201 9.84544 6.4448 9.84544 6.27045C9.84544 6.0961 9.77618 5.92889 9.65289 5.80561C9.52961 5.68232 9.3624 5.61306 9.18805 5.61306C9.0137 5.61306 8.84649 5.68232 8.7232 5.80561L7.65625 6.87256V2.3335C7.65625 2.15945 7.58711 1.99253 7.46404 1.86946C7.34097 1.74639 7.17405 1.67725 7 1.67725C6.82595 1.67725 6.65903 1.74639 6.53596 1.86946C6.41289 1.99253 6.34375 2.15945 6.34375 2.3335V6.87256L5.2768 5.8067C5.15351 5.68342 4.9863 5.61415 4.81195 5.61415C4.6376 5.61415 4.47039 5.68342 4.34711 5.8067C4.22383 5.92998 4.15456 6.09719 4.15456 6.27154C4.15456 6.44589 4.22383 6.6131 4.34711 6.73639L6.5357 8.92279Z"
        fill={fill}
      />
    </svg>
  );
};

export default function ApplicationMobileOverlay({
  logo,
  setIsOpen,
  university,
  course,
  status,
  admission,
  statusUrl,
  letterUrl
}) {
  return (
    <>
      <div className="overlay" onClick={() => setIsOpen(false)}></div>
      <div className="application-modal">
        <img
          src={back}
          alt=""
          className="back"
          onClick={() => setIsOpen(false)}
        />
        <div className="center">
          <img
            src={logo ? logo : `/svg/university.svg`}
            width="56"
            height="56"
            alt="university logo"
          ></img>
          <h3>{university}</h3>
          {course === "undefined" ? "" : <p>{course}</p>}
          
        </div>

        <div className="styles">
          <ApplicationStatus status={status} verbose />
          <span className="mobile-only">•</span>
          <AdmissionStatus status={admission} verbose />
        </div>

        <div className="downloads">
          <div className="download" onClick={() => {  if (statusUrl) {
            window.open(statusUrl);
          }}}>
            <p>Application Docs</p>
            <DownloadSvg  />
          </div>
          <div className="download"           onClick={() => {  if (letterUrl) {
            window.open(letterUrl);
          }}}>
            <p>Admission Docs</p>
            <DownloadSvg />
          </div>
          <div className="download"  onClick={() => {  if (letterUrl) {
            window.open(letterUrl);
          }}}>
            <p>Offer Letter</p>
            <DownloadSvg />
          </div>
        </div>

        <button className="">
          Download All <DownloadSvg fill="#fff" />
        </button>
      </div>
    </>
  );
}
